import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message, Upload, Card } from 'antd';
import { useParams } from 'react-router-dom';
import SmallList from '../../../../components/SmallList';
import Icon from '../../../../components/Icon';
import MessagePopup from '../../../../components/MessagePopup';
import premisesImportsService from '../../../../services/premisesImportsService';
import ProgressModal from '../../../../components/ProgressModal';

export const ImportsList = () => {
  const [imports, setImports] = useState([]);
  const { id } = useParams();
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  useEffect(() => {
    premisesImportsService.getImports(id).then((data) => {
      setImports(data);
    });
  }, [id]);

  const refresh = () => {
    premisesImportsService.getImports(id).then((data) => {
      setImports(data);
    });
  };

  const columns = [
    {
      title: 'Autor',
      dataIndex: 'author',
      editable: false,
    },
    {
      title: 'Ilość rekordów',
      dataIndex: 'records',
      editable: false,
    },
    {
      title: 'Waga',
      dataIndex: 'fileSize',
      editable: false,
      render: (text) => `${text} kB`,
    },
    {
      title: 'Dodano',
      dataIndex: 'createDate',
      editable: false,
    },
  ];

  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('importFile', data.file);
    formData.append('fileName', data.file.name);

    setProgressModalVisible(true);

    premisesImportsService
      .import(id, formData, {
        onUploadProgress: (progressEvent) => {
          setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
      .then((response) => {
        setProgressModalVisible(false);

        if (response.id) {
          message.success(
            <MessagePopup
              type='success'
              annotation={'Twoja baza danych została zaimportowana poprawnie'}
            >
              Import zakończony sukcesem
            </MessagePopup>
          );
          refresh();
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        } else if (err?.response?.status === 406) {
          message.error(
            <MessagePopup
              type={'error'}
              annotation={'Przekroczono limit lokali dla obecnego pakietu'}
            >
              Limit lokali został przekroczony
            </MessagePopup>
          );
        } else {
          message.error(
            <MessagePopup
              type={'error'}
              annotation={'Nie możemy zaimportować pliku'}
            >
              Import nie powiódł się
            </MessagePopup>
          );
        }
      });
  };

  const beforeUpload = (file) => {
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isLt15M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 15MB
        </MessagePopup>
      );
    }
    return isLt15M;
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      customRequest: addNewAction,
      showUploadList: false,
      beforeUpload,
    };

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploadProps}>
        <Button
          className='button-secondary'
          onClick={() => {}}
          style={{ width: '45px', height: '45px' }}
        >
          <Icon name='plus' />
        </Button>
      </Upload>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            data={imports}
            columns={columns}
            title='Lista importów'
            customAddButton={<CustomAddButton />}
            hideEdit
            tableClassName={imports.length !== 0 && 'contacts-table'}
          />
          <Card className='import-info-wrapper'>
            <Button className='button-secondary'>
              <a
                href='http://api.crm.brainstd.com/static/imports/lokaleImport.csv'
                target='_blank'
                rel='noreferrer'
                download
              >
                <Icon name='download' style={{ marginRight: 5 }} />
                Pobierz szablon
              </a>
            </Button>

            <div style={{ marginTop: 40 }}>
              <h2>Jak przygotować plik do importu?</h2>

              <span>
                Poniżej znajdziesz szczegółowe informacje jak przygotować plik,
                który pozwoli Ci zaimportować lokale do systemu Manage CRM.
              </span>

              <ol>
                <li>
                  Akceptowany format plików: <strong>*.csv.</strong>
                </li>
                <li>
                  Maksymalny rozmiar pliku: <strong>15MB</strong>.
                </li>
                <li>
                  Jeśli w pliku wybrana pozycja zostanie pusta - dana wartość w
                  systemie pozostanie nieuzupełniona.
                </li>
                <li>
                  Jeśli w pliku wybrana pozycja zostanie uzupełniona niezgodnie
                  z poniższymi wytycznymi - cały wiersz nie zostanie
                  zaimportowany do systemu.
                </li>
                <li>
                  Akceptowane wartości oraz znaki w poszczególnych polach:
                </li>
              </ol>

              <ul>
                <li>
                  Pole <strong>"Typ"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- wybrana jedna opcja z listy poniżej</div>
                          <div>
                            - opcja 1: <strong>Mieszkanie</strong>
                          </div>
                          <div>
                            - opcja 2: <strong>Lokal usługowy</strong>
                          </div>
                          <div>
                            - opcja 3: <strong>Miejsce postojowe</strong>
                          </div>
                          <div>
                            - opcja 4: <strong>Komórka lokatorska</strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- inne wartości niż te zdefiniowane w TAK</div>
                          <div>- cyfry</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Numery"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- cyfry</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Piętro"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>
                            - cyfry z zakresu od <strong>-5</strong> do{' '}
                            <strong>20</strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- inne wartości niż te zdefiniowane w TAK</div>
                          <div>- litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne (z wykluczeniem "-")</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Lokalizacja"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- wybrana jedna opcja z listy poniżej</div>
                          <div>
                            - opcja 1: <strong>Hala garażowa</strong>
                          </div>
                          <div>
                            - opcja 2: <strong>Garaż</strong>
                          </div>
                          <div>
                            - opcja 3: <strong>Na zewnątrz</strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- inne wartości niż te zdefiniowane w TAK</div>
                          <div>- cyfry</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Data przekazania"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>
                            - 8 cyfr w formacie <strong>RRRR-MM-DD</strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- inne wartości niż te zdefiniowane w TAK</div>
                          <div>- litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne (z wykluczeniem "-")</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Strony świata"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>
                            - wybrana jedna bądź wiele opcji z listy poniżej,
                            zapisane po przecinku
                          </div>
                          <div>
                            - opcja 1: <strong>Wschód</strong>
                          </div>
                          <div>
                            - opcja 2: <strong>Zachód</strong>
                          </div>
                          <div>
                            - opcja 3: <strong>Północ</strong>
                          </div>
                          <div>
                            - opcja 4: <strong>Południe</strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- inne wartości niż te zdefiniowane w TAK</div>
                          <div>- cyfry</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Powierzchnia"</strong> oraz <strong>"Śmieci z wody"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>
                            - cyfry w formacie dziesiętnym <strong>0</strong>{' '}
                            bez wartości po przecinku
                          </div>
                          <div>
                            - cyfry w formacie dziesiętnym <strong>0,00</strong>{' '}
                            z wartościami po przecinku
                          </div>
                          <div>
                            - wartości dziesiętne po przecinku zapisujemy ze
                            znakiem specjalnym przecinkiem "<strong>,</strong>"
                            bądź kropką "<strong>.</strong>"
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- wartości ujemne i zerowe</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                          <div>
                            - znaki specjalne (z wykluczeniem "," oraz ".")
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Ilość lokatorów"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>
                            - cyfry w formacie dziesiętnym <strong>0</strong>{' '}
                            bez wartości po przecinku
                          </div>
                          <div>
                            - cyfry z zakresu od <strong>0</strong> do{' '}
                            <strong>20</strong>
                          </div>
                          <div>- wartości zerowe</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- wartości ujemne</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Ulica"</strong>, <strong>"Numer"</strong>,{' '}
                  <strong>"Lokal"</strong> oraz <strong>"Miejscowość"</strong>:
                  <br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- cyfry</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Kod pocztowy"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>
                            - 5 cyfr w formacie <strong>00-000</strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne (z wykluczeniem "-")</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Kraj"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- cyfry</div>
                          <div>- znaki specjalne (z wykluczeniem "-")</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Notatka"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- cyfry</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
