import BaseService from './baseService';

class TransactionsService extends BaseService {
  constructor(userId) {
    super();
    this.url = `/api/community/users/${userId}/transactions`;
  }
}

export default TransactionsService;
