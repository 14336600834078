import React, { useState } from 'react';
import SmallList from '../../../components/SmallList';
import { BankAccountModal } from './BankAccountModal';

export const BankAccountsList = ({ bankAccounts, setBankAccounts }) => {
  const [showModal, setShowModal] = useState(false);
  const [editingBankAccount, setEditingBankAccount] = useState(null);

  const data = [
    {
      name: 'BASIC',
      bankNumber: bankAccounts.BASIC,
      polishName: 'Podstawowy',
    },
    {
      name: 'RENOVATION',
      bankNumber: bankAccounts.RENOVATION,
      polishName: 'Fundusz remontowy',
    },
    {
      name: 'PARKING',
      bankNumber: bankAccounts.PARKING,
      polishName: 'Miejsce postojowe',
    },
  ];

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'polishName',
      editable: false,
    },
    {
      title: 'Numer rachunku',
      dataIndex: 'bankNumber',
      editable: true,
      render: (text, record) => (text ? text : '-'),
    },
  ];

  return (
    <div className='attachments create-form-wrapper'>
      <BankAccountModal
        isModalVisible={showModal}
        afterCancel={() => {
          setShowModal(false);
          setEditingBankAccount(null);
        }}
        bankAccount={editingBankAccount}
        setBankAccounts={setBankAccounts}
      />
      <SmallList
        data={data}
        columns={columns}
        title='Rachunki do wpłat'
        editingAction={(record) => {
          setEditingBankAccount(record);
          setShowModal(true);
        }}
        updateAction={() => {}}
        hideEdit
        hideDelete
      />
    </div>
  );
};
