import React, { useEffect } from 'react';
import { Modal, Form, Row, Col } from 'antd';
import Footer from '../../../helpers/Footer';
import Input from '../../../components/InputComponent';

const { InputNumberInt } = Input;

export const BankAccountModal = ({
  afterCancel,
  isModalVisible,
  bankAccount,
  setBankAccounts,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (bankAccount) {
      form.setFieldsValue(bankAccount);
    }
  }, [bankAccount]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        setBankAccounts((curr) => ({
          ...curr,
          [bankAccount.name]: values.bankNumber,
        }));

        afterCancel();

        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={
          <span>
            Edytujesz:{' '}
            <span style={{ color: '#5353f4' }}>{bankAccount?.polishName}</span>
          </span>
        }
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={410}
        footer={
          <Footer
            onReset={onReset}
            onFinish={onFinish}
            isNew={true}
            customSendName={'Zapisz'}
          />
        }
      >
        <Form form={form} name='bankAccountModal' onFinish={onFinish}>
          <Row gutter={20}>
            <Col xs={24}>
              <Form.Item
                name='polishName'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input size='large' placeholder='Nazwa' disabled={true} />
              </Form.Item>
              <Form.Item
                name='bankNumber'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('bankNumber').length !== 26) {
                        return Promise.reject(
                          'Rachunek bankowy składa się z 26 liczb!'
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumberInt size='large' placeholder='Numer rachunku' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
