import React, { useEffect, useState } from 'react';
import { Form, Button, PageHeader, Row, Col, message, Card } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import investmentsService from '../../../services/investmentsService';
import settlementTemplatesService from '../../../services/settlementTemplatesService';
import Icon from '../../../components/Icon';
import Input from '../../../components/InputComponent';
import Spin from '../../../components/Spin';
import CountrySelect from '../../../components/CountrySelect';
import MessagePopup from '../../../components/MessagePopup';
import Tabs from '../../../components/Tabs';

const { InputNumberInt, Switch } = Input;
const { Select } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const Create = () => {
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [investment, setInvestment] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getOne(id).then((data) => {
      setInvestment(data);
      setLoading(false);
    });
  }, [id]);

  const onFinish = (values) => {
    const SettlementTemplatesService = new settlementTemplatesService(id);

    SettlementTemplatesService.create(values)
      .then((response) => {
        if (response.id) {
          history.replace(`/investment/${id}/housingSettlements/edit`);
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const onFieldsChange = (fieldChanged) => {
    const fieldChangedNames = fieldChanged
      .map((field) => field.name)
      .reduce((a, b) => a.concat(b));
    if (fieldChangedNames.includes('investmentAddress')) {
      const investmentAddress = form.getFieldValue('investmentAddress');
      if (investmentAddress) {
        form.setFieldsValue({
          companyName: investment.name,
          address: investment.address,
        });
      } else {
        form.setFieldsValue({
          companyName: undefined,
          address: undefined,
        });
      }
    }
  };

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Dodaj</span>;
    }
    return (
      <span>
        Dodaj:{' '}
        <span className='header-subtitle'>Rozliczenia {investment.name}</span>
      </span>
    );
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => window.history.back()}
          title={<HeaderTitle />}
        >
          <Form
            name='create'
            className='create-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={onFieldsChange}
            initialValues={{
              active: true,
              address: { country: 'Polska' },
              daysToPayment: 14,
            }}
            autoComplete='off'
            form={form}
          >
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <div className='premise-investment-address'>
                            <Row gutter={20}>
                              <Col flex={'auto'}>Status rozliczeń</Col>
                              <Col style={{ textAlign: 'right' }}>
                                <Form.Item
                                  name='active'
                                  label='Aktywne'
                                  valuePropName='checked'
                                >
                                  <Switch size='large' />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className='form-section'>
                          <h2>Płatności</h2>
                          <Row gutter={[20, 15]}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='bankTransferTitle'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                                style={{ marginBottom: 0 }}
                              >
                                <Input
                                  size='large'
                                  placeholder='Tytuł przelewu'
                                  addonAfter='_numer_lokalu_data'
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='daysToPayment'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                                style={{ marginBottom: 0 }}
                              >
                                <Select
                                  placeholder='Termin płatności'
                                  size='large'
                                >
                                  <Option value={7}>7 dni</Option>
                                  <Option value={14}>14 dni</Option>
                                  <Option value={21}>21 dni</Option>
                                  <Option value={28}>28 dni</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='settlementSendDay'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                                style={{ marginBottom: 0 }}
                              >
                                <InputNumberInt
                                  size='large'
                                  placeholder='Dzień wysyłania rozliczenia'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className='form-section'>
                          <h2>Adres</h2>
                          <div className='premise-investment-address'>
                            <Row gutter={20}>
                              <Col xs={12}>
                                Pobierz dane{' '}
                                {investment?.type === 'HOUSING_ASSOCIATION'
                                  ? 'spółdzielni'
                                  : investment?.type === 'HOUSING_COMMUNITY'
                                  ? 'wspólnoty'
                                  : 'inwestycji'}
                              </Col>
                              <Col xs={12} style={{ textAlign: 'right' }}>
                                <Form.Item
                                  name='investmentAddress'
                                  valuePropName='checked'
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={'companyName'}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Nazwa firmy' />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={12} />
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'number']}>
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'local']}>
                                <Input size='large' placeholder='Lokal' />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Dodaj
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
