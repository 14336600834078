import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../helpers/PrivateRoute';
import Dashboard from '../routes/dashboard/Dashboard';

// developers
import DevelopersList from '../routes/developers/List';
import CreateDeveloper from '../routes/developers/Create';
import EditDeveloper from '../routes/developers/Edit';
import ShowDeveloper from '../routes/developers/Show';

// tenants
import TenantsList from '../routes/tenants/List';
import CreateTenant from '../routes/tenants/Create';
import EditTenant from '../routes/tenants/Edit';
import ShowTenant from '../routes/tenants/Show';

// clients
import ClientsList from '../routes/clients/List';
import CreateClient from '../routes/clients/Create';
import EditClient from '../routes/clients/Edit';
import ShowClient from '../routes/clients/Show';
import ClientsPremisesList from '../routes/clients/Premises';
import ClientsFaultsList from '../routes/clients/Faults';
import ClientsTenantChangesList from '../routes/clients/TenantChanges';

// clientsTenants
import ClientsTenantsList from '../routes/clientsTenants/List';
import CreateTenantsClient from '../routes/clientsTenants/Create';
import EditTenantsClient from '../routes/clientsTenants/Edit';
import ShowTenantsClient from '../routes/clientsTenants/Show';
import ClientsTenantsPremisesList from '../routes/clientsTenants/Premises';
import ClientsTenantsFaultsList from '../routes/clientsTenants/Faults';
import ClientsTenantsPaymentsList from '../routes/clientsTenants/Payments';

// workers
import WorkersList from '../routes/workers/List';
import CreateWorker from '../routes/workers/Create';
import EditWorker from '../routes/workers/Edit';
import ShowWorker from '../routes/workers/Show';
import WorkerSettlements from '../routes/workers/Settlements';
import WorkersFaultsList from '../routes/workers/Faults';
import WorkerSettlementsHistory from '../routes/workers/SettlementsHistory';
import WorkerSettlementGroups from '../routes/workers/SettlementsGroups';

// investments
import InvestmentsList from '../routes/investments/List';
import InvestmentsCreate from '../routes/investments/Create';
import InvestmentsEdit from '../routes/investments/Edit';
import InvestmentsShow from '../routes/investments/Show';
import InvestmentsStats from '../routes/investments/Stats';
import InvestmentsPayments from '../routes/investments/Payments';
import InvestmentsSettlements from '../routes/investments/Settlements';

// premises
import PremisesList from '../routes/investments/premises/List';
import PremisesCreate from '../routes/investments/premises/Create';
import PremisesEdit from '../routes/investments/premises/Edit';
import PremisesShow from '../routes/investments/premises/Show';

// tenant changes
import TenantChangesCreate from '../routes/tenantChangeGroups/tenantChange/Create';
import TenantChangesEdit from '../routes/tenantChangeGroups/tenantChange/Edit';
import TenantChangesShow from '../routes/tenantChangeGroups/tenantChange/Show';

// tenant changes groups
import TenantChangesGroupList from '../routes/tenantChangeGroups/List';
import TenantChangesGroupCreate from '../routes/tenantChangeGroups/Create';
import TenantChangesGroupEdit from '../routes/tenantChangeGroups/Edit';
import TenantChangesGroupShow from '../routes/tenantChangeGroups/Show';
import TenantChangesGroupHistory from '../routes/tenantChangeGroups/History';

// fault groups
import FaultsGroupList from '../routes/faultGroup/List';
import FaultsGroupCreate from '../routes/faultGroup/Create';
import FaultsGroupEdit from '../routes/faultGroup/Edit';
import FaultsGroupShow from '../routes/faultGroup/Show';
import FaultsGroupHistory from '../routes/faultGroup/History';

// faults
import FaultCreate from '../routes/faultGroup/fault/Create';
import FaultShow from '../routes/faultGroup/fault/Show';
import FaultEdit from '../routes/faultGroup/fault/Edit';

// payments
import PaymentsList from '../routes/payments/List';
import PaymentsCreate from '../routes/payments/Create';
import PaymentsEdit from '../routes/payments/Edit';
import PaymentsShow from '../routes/payments/Show';

// settlements
import SettlementsList from '../routes/settlementsGroup/List';
import SettlementsShow from '../routes/settlementsGroup/Show';

// agreements
import AgreementsList from '../routes/agreemenets/List';

// my account
import MyAccount from '../routes/myAccount/Show';
import MyAccountEdit from '../routes/myAccount/Edit';
import ChangeView from '../routes/myAccount/ChangeView';

// communityAdmins
import CommunityAdminsList from '../routes/communityAdmins/List';
import CommunityAdminsCreate from '../routes/communityAdmins/Create';
import CommunityAdminsShow from '../routes/communityAdmins/Show';
import CommunityAdminsEdit from '../routes/communityAdmins/Edit';

// housingAssociations
import HousingAssociationsList from '../routes/housingAssociations/List';
import HousingAssociationsCreate from '../routes/housingAssociations/Create';
import HousingAssociationsShow from '../routes/housingAssociations/Show';
import HousingAssociationsEdit from '../routes/housingAssociations/Edit';
import HousingAssociationsOptions from '../routes/housingAssociations/Options';
import HousingAssociationsPremisesList from '../routes/housingAssociations/premises/List';
import HousingAssociationsRentCreate from '../routes/housingAssociations/premises/rent/Create';
import HousingAssociationsServiceCreate from '../routes/housingAssociations/premises/service/Create';
import HousingAssociationsParkingCreate from '../routes/housingAssociations/premises/parking/Create';
import HousingAssociationsStorageCreate from '../routes/housingAssociations/premises/storage/Create';
import HousingAssociationsPremisesEdit from '../routes/housingAssociations/premises/Edit';
import HousingAssociationsPremisesShow from '../routes/housingAssociations/premises/Show';
import HousingAssociationsPremisesImport from '../routes/housingAssociations/premises/Import';
import HousingAssociationsMembersList from '../routes/housingAssociations/members/List';

// housingCommunities
import HousingCommunitiesList from '../routes/housingCommunities/List';
import HousingCommunitiesCreate from '../routes/housingCommunities/Create';
import HousingCommunitiesShow from '../routes/housingCommunities/Show';
import HousingCommunitiesEdit from '../routes/housingCommunities/Edit';
import HousingCommunitiesOptions from '../routes/housingCommunities/Options';
import HousingCommunitiesPremisesList from '../routes/housingCommunities/premises/List';
import HousingCommunitiesRentCreate from '../routes/housingCommunities/premises/rent/Create';
import HousingCommunitiesServiceCreate from '../routes/housingCommunities/premises/service/Create';
import HousingCommunitiesParkingCreate from '../routes/housingCommunities/premises/parking/Create';
import HousingCommunitiesStorageCreate from '../routes/housingCommunities/premises/storage/Create';
import HousingCommunitiesPremisesEdit from '../routes/housingCommunities/premises/Edit';
import HousingCommunitiesPremisesShow from '../routes/housingCommunities/premises/Show';
import HousingCommunitiesPremisesImport from '../routes/housingCommunities/premises/Import';
import HousingCommunitiesMembersList from '../routes/housingCommunities/members/List';

// housingPremises
import HousingPremisesList from '../routes/housingPremises/List';

// members
import MembersList from '../routes/members/List';
import MembersCreate from '../routes/members/Create';
import MembersEdit from '../routes/members/Edit';
import MembersShow from '../routes/members/Show';
import MembersImport from '../routes/members/Import';
import MembersPremisesList from '../routes/members/premises/List';
import MembersSettlementsList from '../routes/members/settlements/List';

// membersTransactions
import TransactionsList from '../routes/transactions/List';
import TransactionsCreate from '../routes/transactions/Create';
import TransactionsEdit from '../routes/transactions/Edit';
import MassTransactions from '../routes/transactions/MassTransactions';

// memberAssociations
import MemberAssociationsList from '../routes/memberAssociations/List';
import MemberAssociationsOptions from '../routes/memberAssociations/Options';
import MemberAssociationsShow from '../routes/memberAssociations/Show';
import MemberAssociationsPremisesList from '../routes/memberAssociations/premises/List';
import MemberAssociationsPremisesShow from '../routes/memberAssociations/premises/Show';
import MemberAssociationsPremisesEdit from '../routes/memberAssociations/premises/Edit';
import MemberAssociationsTenantsList from '../routes/memberAssociations/tenants/List';

// memberCommunities
import MemberCommunitiesList from '../routes/memberCommunities/List';
import MemberCommunitiesOptions from '../routes/memberCommunities/Options';
import MemberCommunitiesShow from '../routes/memberCommunities/Show';
import MemberCommunitiesPremisesList from '../routes/memberCommunities/premises/List';
import MemberCommunitiesPremisesShow from '../routes/memberCommunities/premises/Show';
import MemberCommunitiesPremisesEdit from '../routes/memberCommunities/premises/Edit';
import MemberCommunitiesTenantsList from '../routes/memberCommunities/tenants/List';

// memberPremises
import MemberPremisesList from '../routes/memberPremises/List';

// housingWorkers
import HousingWorkersList from '../routes/housingWorkers/List';
import HousingWorkersCreate from '../routes/housingWorkers/Create';
import HousingWorkersEdit from '../routes/housingWorkers/Edit';
import HousingWorkersShow from '../routes/housingWorkers/Show';

// housingTenants
import HousingTenantsList from '../routes/housingTenants/List';
import HousingTenantsCreate from '../routes/housingTenants/Create';
import HousingTenantsEdit from '../routes/housingTenants/Edit';
import HousingTenantsShow from '../routes/housingTenants/Show';
import HousingTenantsPremisesShow from '../routes/housingTenants/premises/List';
import HousingTenantsSettlementsList from '../routes/housingTenants/settlements/List';

// tenantAssociations
import TenantAssociationsList from '../routes/tenantAssociations/List';
import TenantAssociationsOptions from '../routes/tenantAssociations/Options';
import TenantAssociationsShow from '../routes/tenantAssociations/Show';
import TenantAssociationsPremisesList from '../routes/tenantAssociations/premises/List';
import TenantAssociationsPremisesShow from '../routes/tenantAssociations/premises/Show';

// tenantCommunities
import TenantCommunitiesList from '../routes/tenantCommunities/List';
import TenantCommunitiesOptions from '../routes/tenantCommunities/Options';
import TenantCommunitiesShow from '../routes/tenantCommunities/Show';
import TenantCommunitiesPremisesList from '../routes/tenantCommunities/premises/List';
import TenantCommunitiesPremisesShow from '../routes/tenantCommunities/premises/Show';

// tenantPremises
import TenantPremisesList from '../routes/tenantPremises/List';

// counters
import InvestmentCountersList from '../routes/counters/investment/List';
import InvestmentCountersCreate from '../routes/counters/investment/Create';
import InvestmentCountersEdit from '../routes/counters/investment/Edit';
import InvestmentCountersShow from '../routes/counters/investment/Show';
import InvestmentSubCountersList from '../routes/counters/investment/subCounters/List';
import InvestmentSubCountersCreate from '../routes/counters/investment/subCounters/Create';
import InvestmentSubCountersEdit from '../routes/counters/investment/subCounters/Edit';
import InvestmentSubCountersShow from '../routes/counters/investment/subCounters/Show';
import MemberSubCountersShow from '../routes/counters/memberInvestment/subCounters/Show';
import TenantSubCountersShow from '../routes/counters/tenantInvestment/subCounters/Show';
import SubCountersImport from '../routes/counters/investment/subCounters/Import';

// housingSettlements
import HousingSettlementsList from '../routes/housingSettlements/List';
import InvestmentSettlementsList from '../routes/housingSettlements/investment/List';
import InvestmentSettlementsCreate from '../routes/housingSettlements/settlementsTemplates/Create';
import InvestmentSettlementsEdit from '../routes/housingSettlements/settlementsTemplates/Edit';

import SettlementsCostsGroupsCreate from '../routes/housingSettlements/settlementsTemplates/costsGroups/general/Create';
import SettlementsCostsGroupsEdit from '../routes/housingSettlements/settlementsTemplates/costsGroups/general/Edit';
import SettlementsIndividualCostsGroupsCreate from '../routes/housingSettlements/settlementsTemplates/costsGroups/individual/Create';
import SettlementsIndividualCostsGroupsEdit from '../routes/housingSettlements/settlementsTemplates/costsGroups/individual/Edit';

import SettlementEdit from '../routes/housingSettlements/investment/Edit';
import SettlementShow from '../routes/housingSettlements/investment/Show';
import SettlementImport from '../routes/housingSettlements/investment/Import';

// memberSettlements
import MemberSettlementsList from '../routes/memberSettlements/List';
import MemberInvestmentSettlementsList from '../routes/memberSettlements/investment/List';

import MemberSettlementShow from '../routes/memberSettlements/investment/Show';
import MemberSettlementPayments from '../routes/memberSettlements/Payments';

// tenantSettlements
import TenantSettlementsList from '../routes/tenantSettlements/List';
import TenantInvestmentSettlementsList from '../routes/tenantSettlements/investment/List';

import TenantSettlementShow from '../routes/tenantSettlements/investment/Show';
import TenantSettlementPayments from '../routes/tenantSettlements/Payments';

// housing fault groups
import HousingFaultsGroupList from '../routes/housingFaultGroup/List';
import HousingFaultsGroupCreate from '../routes/housingFaultGroup/Create';
import HousingFaultsGroupEdit from '../routes/housingFaultGroup/Edit';
import HousingFaultsGroupShow from '../routes/housingFaultGroup/Show';
import HousingFaultsGroupHistory from '../routes/housingFaultGroup/History';

// housing faults
import HousingFaultCreate from '../routes/housingFaultGroup/fault/Create';
import HousingFaultShow from '../routes/housingFaultGroup/fault/Show';
import HousingFaultEdit from '../routes/housingFaultGroup/fault/Edit';
import HousingFaultTemplate from '../routes/housingFaultGroup/fault/FaultTemplate';

// resolutions
import ResolutionsCreate from '../routes/resolutions/Create';
import ResolutionsShow from '../routes/resolutions/Show';
import ResolutionsEdit from '../routes/resolutions/Edit';
import ResolutionsList from '../routes/resolutions/List';
import ResolutionsDetails from '../routes/resolutions/Details';

// documents
import DocumentsCreate from '../routes/documents/Create';
import DocumentsEdit from '../routes/documents/Edit';
import DocumentsList from '../routes/documents/List';

// messages
import MessagesList from '../routes/messages/List';
import MessagesCreate from '../routes/messages/Create';
import MessagesShow from '../routes/messages/Show';
import MessagesEdit from '../routes/messages/Edit';
import MemberMessagesShow from '../routes/messages/member/Show';

// assignOwners
import AssignOwners from '../routes/housingPremises/AssignOwners';

// addCountersReads
import AddCountersReads from '../routes/housingPremises/AddCountersReads';

// packages
import PackagesList from '../routes/packages/List';
import PackageCreate from '../routes/packages/Create';
import PackageEdit from '../routes/packages/Edit';
import PackageShow from '../routes/packages/Show';
import ChangePackage from '../routes/myAccount/ChangePackage';

// premises all
import PremisesAllList from '../routes/premisesAll/List';

// receipts
import ReceiptsList from '../routes/receipts/List';

// tags
import TagsList from '../routes/tags/List';

// receivables
import ReceivablesList from '../routes/receivables/List';

// rodo
import RodoPage from '../routes/rodo/Rodo';

const Routes = ({ userData }) => (
  <Switch>
    <PrivateRoute path='/dashboard'>
      <Dashboard />
    </PrivateRoute>
    <PrivateRoute path='/developers/create'>
      <CreateDeveloper />
    </PrivateRoute>
    <PrivateRoute path='/developers/:id/edit'>
      <EditDeveloper />
    </PrivateRoute>
    <PrivateRoute path='/developers/:id/show'>
      <ShowDeveloper />
    </PrivateRoute>
    <PrivateRoute path='/developers'>
      <DevelopersList />
    </PrivateRoute>
    <PrivateRoute path='/tenants/create'>
      <CreateTenant />
    </PrivateRoute>
    <PrivateRoute path='/tenants/:id/edit'>
      <EditTenant />
    </PrivateRoute>
    <PrivateRoute path='/tenants/:id/show'>
      <ShowTenant />
    </PrivateRoute>
    <PrivateRoute path='/tenants'>
      <TenantsList />
    </PrivateRoute>
    <PrivateRoute path='/clients/create'>
      <CreateClient />
    </PrivateRoute>
    <PrivateRoute path='/clients/:id/edit'>
      <EditClient />
    </PrivateRoute>
    <PrivateRoute path='/clients/:id/show'>
      <ShowClient />
    </PrivateRoute>
    <PrivateRoute path='/clients/:id/premises'>
      <ClientsPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/clients/:id/faultGroup'>
      <ClientsFaultsList />
    </PrivateRoute>
    <PrivateRoute path='/clients/:id/tenantChangeGroups'>
      <ClientsTenantChangesList />
    </PrivateRoute>
    <PrivateRoute path='/clients'>
      <ClientsList />
    </PrivateRoute>
    <PrivateRoute path='/clientsTenants/create'>
      <CreateTenantsClient />
    </PrivateRoute>
    <PrivateRoute path='/clientsTenants/:id/edit'>
      <EditTenantsClient />
    </PrivateRoute>
    <PrivateRoute path='/clientsTenants/:id/show'>
      <ShowTenantsClient />
    </PrivateRoute>
    <PrivateRoute path='/clientsTenants/:id/premises'>
      <ClientsTenantsPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/clientsTenants/:id/faultGroup'>
      <ClientsTenantsFaultsList />
    </PrivateRoute>
    <PrivateRoute path='/clientsTenants/:id/payments'>
      <ClientsTenantsPaymentsList />
    </PrivateRoute>
    <PrivateRoute path='/clientsTenants'>
      <ClientsTenantsList />
    </PrivateRoute>
    <PrivateRoute path='/workers/create'>
      <CreateWorker />
    </PrivateRoute>
    <PrivateRoute path='/workers/:id/edit'>
      <EditWorker />
    </PrivateRoute>
    <PrivateRoute path='/workers/:id/show'>
      <ShowWorker />
    </PrivateRoute>
    <PrivateRoute path='/workers/:id/settlementGroups'>
      <WorkerSettlementGroups />
    </PrivateRoute>
    <PrivateRoute path='/workers/:id/settlements/history'>
      <WorkerSettlementsHistory />
    </PrivateRoute>
    <PrivateRoute path='/workers/:id/settlements'>
      <WorkerSettlements />
    </PrivateRoute>
    <PrivateRoute path='/workers/:id/faults'>
      <WorkersFaultsList />
    </PrivateRoute>
    <PrivateRoute path='/workers'>
      <WorkersList />
    </PrivateRoute>
    <PrivateRoute path='/investments/:id/premises/:premisesId/show'>
      <PremisesShow />
    </PrivateRoute>
    <PrivateRoute path='/investments/:id/premises/:premisesId/edit'>
      <PremisesEdit />
    </PrivateRoute>
    <PrivateRoute path='/investments/:id/premises/create'>
      <PremisesCreate />
    </PrivateRoute>
    <PrivateRoute path='/investments/:id/premises'>
      <PremisesList />
    </PrivateRoute>
    <PrivateRoute path='/investments/:id/show'>
      <InvestmentsShow />
    </PrivateRoute>
    <PrivateRoute path='/investments/:id/payments'>
      <InvestmentsPayments />
    </PrivateRoute>
    <PrivateRoute path='/investments/:id/settlements'>
      <InvestmentsSettlements />
    </PrivateRoute>
    <PrivateRoute path='/investments/:id/edit'>
      <InvestmentsEdit />
    </PrivateRoute>
    <PrivateRoute path='/investments/stats'>
      <InvestmentsStats />
    </PrivateRoute>
    <PrivateRoute path='/investments/create'>
      <InvestmentsCreate />
    </PrivateRoute>
    <PrivateRoute path='/investments'>
      <InvestmentsList />
    </PrivateRoute>
    <PrivateRoute path='/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/tenantChange/:id/show'>
      <TenantChangesShow />
    </PrivateRoute>
    <PrivateRoute path='/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/tenantChange/:id/edit'>
      <TenantChangesEdit />
    </PrivateRoute>
    <PrivateRoute path='/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/tenantChange/create'>
      <TenantChangesCreate />
    </PrivateRoute>
    <PrivateRoute path='/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/history'>
      <TenantChangesGroupHistory />
    </PrivateRoute>
    <PrivateRoute path='/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/show'>
      <TenantChangesGroupShow />
    </PrivateRoute>
    <PrivateRoute path='/tenantChangeGroup/:investmentId/premises/:premisesId/tenantChangeGroup/:tenantChangeGroupId/edit'>
      <TenantChangesGroupEdit />
    </PrivateRoute>
    <PrivateRoute path='/tenantChangeGroup/create'>
      <TenantChangesGroupCreate />
    </PrivateRoute>
    <PrivateRoute path='/tenantChangeGroup'>
      <TenantChangesGroupList />
    </PrivateRoute>
    <PrivateRoute path='/faultGroup/:investmentId/faultGroup/:faultGroupId/fault/:faultId/edit'>
      <FaultEdit />
    </PrivateRoute>
    <PrivateRoute path='/faultGroup/:investmentId/faultGroup/:faultGroupId/fault/:faultId/show'>
      <FaultShow />
    </PrivateRoute>
    <PrivateRoute path='/faultGroup/:investmentId/faultGroup/:faultGroupId/fault/create'>
      <FaultCreate />
    </PrivateRoute>
    <PrivateRoute path='/faultGroup/:investmentId/faultGroup/:faultGroupId/history'>
      <FaultsGroupHistory />
    </PrivateRoute>
    <PrivateRoute path='/faultGroup/:investmentId/faultGroup/:faultGroupId/show'>
      <FaultsGroupShow />
    </PrivateRoute>
    <PrivateRoute path='/faultGroup/:investmentId/faultGroup/:faultGroupId/edit'>
      <FaultsGroupEdit />
    </PrivateRoute>
    <PrivateRoute path='/faultGroup/create'>
      <FaultsGroupCreate />
    </PrivateRoute>
    <PrivateRoute path='/faultGroup'>
      <FaultsGroupList />
    </PrivateRoute>
    <PrivateRoute path='/payments/:investmentId/premises/:premisesId/agreement/:agreementId/payment/:id/edit'>
      <PaymentsEdit />
    </PrivateRoute>
    <PrivateRoute path='/payments/:investmentId/premises/:premisesId/agreement/:agreementId/payment/:id/show'>
      <PaymentsShow />
    </PrivateRoute>
    <PrivateRoute path='/payments/create'>
      <PaymentsCreate />
    </PrivateRoute>
    <PrivateRoute path='/packagePayments'>
      <PaymentsList type='PACKAGE' />
    </PrivateRoute>
    <PrivateRoute path='/payments'>
      <PaymentsList type='AGREEMENT' />
    </PrivateRoute>
    <PrivateRoute path='/settlements/:userId/:id/show'>
      <SettlementsShow />
    </PrivateRoute>
    <PrivateRoute path='/settlements'>
      <SettlementsList />
    </PrivateRoute>
    <PrivateRoute path='/agreements'>
      <AgreementsList />
    </PrivateRoute>
    <PrivateRoute path='/myAccount/edit'>
      <MyAccountEdit />
    </PrivateRoute>
    <PrivateRoute path='/myAccount'>
      <MyAccount />
    </PrivateRoute>
    <PrivateRoute path='/changePackage'>
      <ChangePackage />
    </PrivateRoute>
    <PrivateRoute path='/changeView'>
      <ChangeView />
    </PrivateRoute>
    <PrivateRoute path='/communityAdmins/create'>
      <CommunityAdminsCreate />
    </PrivateRoute>
    <PrivateRoute path='/communityAdmins/:id/edit'>
      <CommunityAdminsEdit />
    </PrivateRoute>
    <PrivateRoute path='/communityAdmins/:id/show'>
      <CommunityAdminsShow />
    </PrivateRoute>
    <PrivateRoute path='/communityAdmins'>
      <CommunityAdminsList />
    </PrivateRoute>

    <PrivateRoute path='/housingAssociations/:id/show'>
      <HousingAssociationsShow />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/edit'>
      <HousingAssociationsEdit />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/premises/import'>
      <HousingAssociationsPremisesImport />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/premises/create/rent'>
      <HousingAssociationsRentCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/premises/create/service'>
      <HousingAssociationsServiceCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/premises/create/parking'>
      <HousingAssociationsParkingCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/premises/create/storage'>
      <HousingAssociationsStorageCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/premises/:premisesId/edit'>
      <HousingAssociationsPremisesEdit />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/premises/:premisesId/show'>
      <HousingAssociationsPremisesShow />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/premises'>
      <HousingAssociationsPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id/members'>
      <HousingAssociationsMembersList />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/create'>
      <HousingAssociationsCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations/:id'>
      <HousingAssociationsOptions userData={userData} />
    </PrivateRoute>
    <PrivateRoute path='/housingAssociations'>
      <HousingAssociationsList />
    </PrivateRoute>

    <PrivateRoute path='/housingCommunities/:id/show'>
      <HousingCommunitiesShow />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/edit'>
      <HousingCommunitiesEdit />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/premises/import'>
      <HousingCommunitiesPremisesImport />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/premises/create/rent'>
      <HousingCommunitiesRentCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/premises/create/service'>
      <HousingCommunitiesServiceCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/premises/create/parking'>
      <HousingCommunitiesParkingCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/premises/create/storage'>
      <HousingCommunitiesStorageCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/premises/:premisesId/edit'>
      <HousingCommunitiesPremisesEdit />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/premises/:premisesId/show'>
      <HousingCommunitiesPremisesShow />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/premises'>
      <HousingCommunitiesPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id/members'>
      <HousingCommunitiesMembersList />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/create'>
      <HousingCommunitiesCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities/:id'>
      <HousingCommunitiesOptions userData={userData} />
    </PrivateRoute>
    <PrivateRoute path='/housingCommunities'>
      <HousingCommunitiesList />
    </PrivateRoute>

    <PrivateRoute path='/housingPremises'>
      <HousingPremisesList />
    </PrivateRoute>

    <PrivateRoute path='/members/:memberId/transactions/create'>
      <TransactionsCreate />
    </PrivateRoute>
    <PrivateRoute path='/members/:memberId/transactions/:transactionId/edit'>
      <TransactionsEdit />
    </PrivateRoute>
    <PrivateRoute path='/members/:memberId/transactions'>
      <TransactionsList />
    </PrivateRoute>
    <PrivateRoute path='/members/massTransactions'>
      <MassTransactions />
    </PrivateRoute>

    <PrivateRoute path='/members/create'>
      <MembersCreate />
    </PrivateRoute>
    <PrivateRoute path='/members/import'>
      <MembersImport />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/members/:userId/show'>
      <MembersShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/members/:userId/premises'>
      <MembersPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/members/:id/edit'>
      <MembersEdit />
    </PrivateRoute>
    <PrivateRoute path='/members/:id/settlements'>
      <MembersSettlementsList />
    </PrivateRoute>
    <PrivateRoute path='/members'>
      <MembersList />
    </PrivateRoute>

    <PrivateRoute path='/memberAssociations/:id/premises/:premisesId/show'>
      <MemberAssociationsPremisesShow />
    </PrivateRoute>
    <PrivateRoute path='/memberAssociations/:id/premises/:premisesId/edit'>
      <MemberAssociationsPremisesEdit />
    </PrivateRoute>
    <PrivateRoute path='/memberAssociations/:id/premises'>
      <MemberAssociationsPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/memberAssociations/:id/tenants'>
      <MemberAssociationsTenantsList />
    </PrivateRoute>
    <PrivateRoute path='/memberAssociations/:id/show'>
      <MemberAssociationsShow />
    </PrivateRoute>
    <PrivateRoute path='/memberAssociations/:id'>
      <MemberAssociationsOptions />
    </PrivateRoute>
    <PrivateRoute path='/memberAssociations'>
      <MemberAssociationsList />
    </PrivateRoute>

    <PrivateRoute path='/memberCommunities/:id/premises/:premisesId/show'>
      <MemberCommunitiesPremisesShow />
    </PrivateRoute>
    <PrivateRoute path='/memberCommunities/:id/premises/:premisesId/edit'>
      <MemberCommunitiesPremisesEdit />
    </PrivateRoute>
    <PrivateRoute path='/memberCommunities/:id/premises'>
      <MemberCommunitiesPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/memberCommunities/:id/tenants'>
      <MemberCommunitiesTenantsList />
    </PrivateRoute>
    <PrivateRoute path='/memberCommunities/:id/show'>
      <MemberCommunitiesShow />
    </PrivateRoute>
    <PrivateRoute path='/memberCommunities/:id'>
      <MemberCommunitiesOptions />
    </PrivateRoute>
    <PrivateRoute path='/memberCommunities'>
      <MemberCommunitiesList />
    </PrivateRoute>

    <PrivateRoute path='/memberPremises'>
      <MemberPremisesList />
    </PrivateRoute>

    <PrivateRoute path='/housingWorkers/create'>
      <HousingWorkersCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingWorkers/:id/show'>
      <HousingWorkersShow />
    </PrivateRoute>
    <PrivateRoute path='/housingWorkers/:id/edit'>
      <HousingWorkersEdit />
    </PrivateRoute>
    <PrivateRoute path='/housingWorkers'>
      <HousingWorkersList />
    </PrivateRoute>

    <PrivateRoute path='/housingTenants/create'>
      <HousingTenantsCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingTenants/:id/show'>
      <HousingTenantsShow />
    </PrivateRoute>
    <PrivateRoute path='/housingTenants/:id/edit'>
      <HousingTenantsEdit />
    </PrivateRoute>
    <PrivateRoute path='/housingTenants/:id/premises'>
      <HousingTenantsPremisesShow />
    </PrivateRoute>
    <PrivateRoute path='/housingTenants/:id/settlements'>
      <HousingTenantsSettlementsList />
    </PrivateRoute>
    <PrivateRoute path='/housingTenants'>
      <HousingTenantsList />
    </PrivateRoute>

    <PrivateRoute path='/tenantAssociations/:id/premises/:premisesId/show'>
      <TenantAssociationsPremisesShow userData={userData} />
    </PrivateRoute>
    <PrivateRoute path='/tenantAssociations/:id/premises'>
      <TenantAssociationsPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/tenantAssociations/:id/show'>
      <TenantAssociationsShow />
    </PrivateRoute>
    <PrivateRoute path='/tenantAssociations/:id'>
      <TenantAssociationsOptions />
    </PrivateRoute>
    <PrivateRoute path='/tenantAssociations'>
      <TenantAssociationsList />
    </PrivateRoute>

    <PrivateRoute path='/tenantCommunities/:id/premises/:premisesId/show'>
      <TenantCommunitiesPremisesShow userData={userData} />
    </PrivateRoute>
    <PrivateRoute path='/tenantCommunities/:id/premises'>
      <TenantCommunitiesPremisesList />
    </PrivateRoute>
    <PrivateRoute path='/tenantCommunities/:id/show'>
      <TenantCommunitiesShow />
    </PrivateRoute>
    <PrivateRoute path='/tenantCommunities/:id'>
      <TenantCommunitiesOptions />
    </PrivateRoute>
    <PrivateRoute path='/tenantCommunities'>
      <TenantCommunitiesList />
    </PrivateRoute>

    <PrivateRoute path='/tenantPremises'>
      <TenantPremisesList />
    </PrivateRoute>

    <PrivateRoute path='/memberInvestment/:id/premises/:premisesId/counters/:counterId/subCounters/:subCounterId/show'>
      <MemberSubCountersShow />
    </PrivateRoute>
    <PrivateRoute path='/tenantInvestment/:id/premises/:premisesId/counters/:counterId/subCounters/:subCounterId/show'>
      <TenantSubCountersShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters/:counterId/subCounters/:subCounterId/edit'>
      <InvestmentSubCountersEdit />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters/:counterId/subCounters/:subCounterId/show'>
      <InvestmentSubCountersShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters/:counterId/subCounters/import'>
      <SubCountersImport />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters/:counterId/subCounters/create'>
      <InvestmentSubCountersCreate />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters/create'>
      <InvestmentCountersCreate />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters/:counterId/subCounters'>
      <InvestmentSubCountersList />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters/:counterId/edit'>
      <InvestmentCountersEdit />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters/:counterId/show'>
      <InvestmentCountersShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/counters'>
      <InvestmentCountersList />
    </PrivateRoute>

    <PrivateRoute path='/investment/:id/premises/:premisesId/housingSettlements/:settlementId/show'>
      <SettlementShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/premises/:premisesId/housingSettlements/:settlementId/edit'>
      <SettlementEdit />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/housingSettlements/costsGroups/:groupId/edit'>
      <SettlementsCostsGroupsEdit />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/housingSettlements/costsGroups/create'>
      <SettlementsCostsGroupsCreate />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/housingSettlements/individualCostsGroups/:groupId/edit'>
      <SettlementsIndividualCostsGroupsEdit />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/housingSettlements/individualCostsGroups/create'>
      <SettlementsIndividualCostsGroupsCreate />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/housingSettlements/edit'>
      <InvestmentSettlementsEdit />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/housingSettlements/create'>
      <InvestmentSettlementsCreate />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/housingSettlements/import'>
      <SettlementImport />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/housingSettlements'>
      <InvestmentSettlementsList userData={userData} />
    </PrivateRoute>
    <PrivateRoute path='/housingSettlements'>
      <HousingSettlementsList userData={userData} />
    </PrivateRoute>

    <PrivateRoute path='/investment/:id/premises/:premisesId/memberSettlements/:settlementId/show'>
      <MemberSettlementShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/premises/:premisesId/memberSettlements/:settlementId/payment/:paymentId/show'>
      <MemberSettlementPayments />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/memberSettlements'>
      <MemberInvestmentSettlementsList />
    </PrivateRoute>
    <PrivateRoute path='/memberSettlements'>
      <MemberSettlementsList />
    </PrivateRoute>

    <PrivateRoute path='/investment/:id/premises/:premisesId/tenantSettlements/:settlementId/show'>
      <TenantSettlementShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/premises/:premisesId/tenantSettlements/:settlementId/payment/:paymentId/show'>
      <TenantSettlementPayments />
    </PrivateRoute>
    <PrivateRoute path='/investment/:id/tenantSettlements'>
      <TenantInvestmentSettlementsList />
    </PrivateRoute>
    <PrivateRoute path='/tenantSettlements'>
      <TenantSettlementsList />
    </PrivateRoute>

    <PrivateRoute path='/housingFaultGroup/:investmentId/faultGroup/:faultGroupId/fault/:faultId/edit'>
      <HousingFaultEdit />
    </PrivateRoute>
    <PrivateRoute path='/housingFaultGroup/:investmentId/faultGroup/:faultGroupId/fault/:faultId/show'>
      <HousingFaultShow />
    </PrivateRoute>
    <PrivateRoute path='/housingFaultGroup/:investmentId/faultGroup/:faultGroupId/fault/:faultId/faultTemplate'>
      <HousingFaultTemplate />
    </PrivateRoute>
    <PrivateRoute path='/housingFaultGroup/:investmentId/faultGroup/:faultGroupId/fault/create'>
      <HousingFaultCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingFaultGroup/:investmentId/faultGroup/:faultGroupId/history'>
      <HousingFaultsGroupHistory />
    </PrivateRoute>
    <PrivateRoute path='/housingFaultGroup/:investmentId/faultGroup/:faultGroupId/show'>
      <HousingFaultsGroupShow />
    </PrivateRoute>
    <PrivateRoute path='/housingFaultGroup/:investmentId/faultGroup/:faultGroupId/edit'>
      <HousingFaultsGroupEdit />
    </PrivateRoute>
    <PrivateRoute path='/housingFaultGroup/create'>
      <HousingFaultsGroupCreate />
    </PrivateRoute>
    <PrivateRoute path='/housingFaultGroup'>
      <HousingFaultsGroupList />
    </PrivateRoute>

    <PrivateRoute path='/resolutions/create'>
      <ResolutionsCreate />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/resolutions/:resolutionId/show'>
      <ResolutionsShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/resolutions/:resolutionId/edit'>
      <ResolutionsEdit />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/resolutions/:resolutionId/details'>
      <ResolutionsDetails />
    </PrivateRoute>
    <PrivateRoute path='/resolutions'>
      <ResolutionsList />
    </PrivateRoute>

    <PrivateRoute path='/documents/create'>
      <DocumentsCreate />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/documents/:documentId/edit'>
      <DocumentsEdit />
    </PrivateRoute>
    <PrivateRoute path='/documents'>
      <DocumentsList userData={userData} />
    </PrivateRoute>

    <PrivateRoute path='/investment/:investmentId/messages/:messageId/show'>
      <MessagesShow />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/messages/:messageId/edit'>
      <MessagesEdit />
    </PrivateRoute>
    <PrivateRoute path='/investment/:investmentId/messages/create'>
      <MessagesCreate />
    </PrivateRoute>
    <PrivateRoute path='/messages/:messageId/show'>
      <MemberMessagesShow />
    </PrivateRoute>
    <PrivateRoute path='/messages'>
      <MessagesList userData={userData} />
    </PrivateRoute>

    <PrivateRoute path='/investment/:investmentId/assignOwners'>
      <AssignOwners />
    </PrivateRoute>

    <PrivateRoute path='/investment/:investmentId/addCountersReads'>
      <AddCountersReads />
    </PrivateRoute>

    <PrivateRoute path='/packages/create'>
      <PackageCreate />
    </PrivateRoute>
    <PrivateRoute path='/packages/:id/edit'>
      <PackageEdit />
    </PrivateRoute>
    <PrivateRoute path='/packages/:id/show'>
      <PackageShow />
    </PrivateRoute>
    <PrivateRoute path='/packages'>
      <PackagesList />
    </PrivateRoute>
    <PrivateRoute path='/premises'>
      <PremisesAllList />
    </PrivateRoute>
    <PrivateRoute path='/receipts'>
      <ReceiptsList />
    </PrivateRoute>
    <PrivateRoute path='/tags'>
      <TagsList />
    </PrivateRoute>
    <PrivateRoute path='/receivables'>
      <ReceivablesList />
    </PrivateRoute>
    <PrivateRoute path='/rodo'>
      <RodoPage />
    </PrivateRoute>
    <Route exact path='/'>
      <Redirect to='/login' />
    </Route>
    <Route path='*'>
      <Redirect to='/404' />
    </Route>
  </Switch>
);

export default Routes;
