/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { PageHeader, Button, Row, Col, Card, Form } from 'antd';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Input from '../../components/InputComponent';
import HousingSettlementsService from '../../services/housingSettlementsService';
import ClientsService from '../../services/clientsService';
import transactionsService from '../../services/transactionsService';
import moment from 'moment';

const { DatePicker, InputNumberV2 } = Input;

const OwnerForm = forwardRef(({ member }, ref) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const initValues = {
      firstName: member?.firstName,
      lastName: member?.lastName,
      transactionDate: moment(),
    };
    form.setFieldsValue(initValues);
  }, [member]);

  useImperativeHandle(ref, () => ({
    async validateForm() {
      return form.validateFields().catch((err) => {
        throw err;
      });
    },
    async submitForm() {
      form.validateFields().then((values) => {
        if (values.price) {
          const TransactionsService = new transactionsService(member.memberId);

          const tempValues = {
            ...values,
            transactionDate: values.transactionDate.format('YYYY-MM-DD'),
          };

          return TransactionsService.create(tempValues);
        }
      });
    },
  }));

  return (
    <Form
      name='create'
      className='create-form'
      // onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
    >
      <Card className='assignSingleCard' style={{ marginBottom: 10 }}>
        <Row gutter={20}>
          <Col xs={24} lg={12} xxl={8}>
            <Form.Item
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Proszę uzupełnić nazwę',
                },
              ]}
            >
              <Input size='large' placeholder='Imię' disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <Form.Item
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Proszę uzupełnić nazwę',
                },
              ]}
            >
              <Input size='large' placeholder='Nazwisko' disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} lg={12} xxl={8}>
            <Form.Item
              name='title'
              rules={[
                {
                  required: true,
                  message: 'Proszę uzupełnić nazwę',
                },
              ]}
            >
              <Input size='large' placeholder='Nazwa operacji' />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <Form.Item
              name='price'
              rules={[
                {
                  required: true,
                  message: 'Proszę podać kwotę',
                },
              ]}
            >
              <InputNumberV2
                size='large'
                placeholder='Kwota'
                addonAfter={'zł brutto'}
                allowNegative
              />
            </Form.Item>
            <div
              style={{
                color: '#B1CADE',
                fontSize: 12,
                paddingLeft: 15,
                marginTop: -10,
                marginBottom: 20,
              }}
            >
              Obecne saldo:
              {member.balance < 0 ? (
                <span
                  style={{ color: '#15D55E', fontWeight: 700, marginLeft: 5 }}
                >
                  {parseFloat(-member.balance).toFixed(2).replace('.', ',')} zł
                </span>
              ) : member.balance > 0 ? (
                <span
                  style={{ color: '#EC434C', fontWeight: 700, marginLeft: 5 }}
                >
                  {parseFloat(-member.balance).toFixed(2).replace('.', ',')} zł
                </span>
              ) : (
                <span style={{ fontWeight: 700, marginLeft: 5 }}>
                  {parseFloat(-member.balance).toFixed(2).replace('.', ',')} zł
                </span>
              )}
            </div>
          </Col>
          <Col xs={24} lg={12} xxl={8}>
            <Form.Item name='transactionDate'>
              <DatePicker
                size='large'
                placeholder='Data dodania'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
});

const useQuery = () => new URLSearchParams(useLocation().search);

const MassTransactions = () => {
  const { investmentId } = useParams();
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const itemsRefs = useRef(Array(query.getAll('memberId').length));

  useEffect(() => {
    const membersId = query.getAll('memberId');

    const membersData = [];

    const promises = membersId.map((memberId) =>
      Promise.all([
        ClientsService.getOne(memberId),
        HousingSettlementsService.getMemberBalance(memberId),
      ]).then(([memberData, balanceData]) => {
        membersData.push({
          memberId: memberData.id,
          firstName: memberData.firstName,
          lastName: memberData.lastName,
          balance: balanceData.balance,
        });
      })
    );

    Promise.all(promises).then(() => {
      setLoading(false);
      setMembers(membersData);
    });
  }, [investmentId]);

  const handleSubmit = () => {
    const validatePromises = [];

    for (let itemRef of itemsRefs.current) {
      validatePromises.push(itemRef.validateForm());
    }

    Promise.all(validatePromises)
      .then(() => {
        const submitPromises = [];

        for (let itemRef of itemsRefs.current) {
          submitPromises.push(itemRef.submitForm());
        }

        Promise.all(submitPromises).then(() => {
          history.goBack();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Dodaj saldo'
          className='page-header-extra-2'
        >
          <Card className='assignCard'>
            {members.map((member, idx) => (
              <OwnerForm
                key={idx}
                member={member}
                ref={(el) => {
                  itemsRefs.current[idx] = el;
                }}
              />
            ))}
          </Card>
          <Row className='form-actions' justify='space-between'>
            <Col sm={4} className='form-actions-cancel-wrapper'>
              <Button
                className='button-secondary'
                size='large'
                onClick={() => window.history.back()}
              >
                Anuluj
              </Button>
            </Col>
            <Col sm={4} className='form-actions-save-wrapper'>
              <Button
                type='primary'
                size='large'
                className='create-form-button'
                onClick={handleSubmit}
              >
                Zapisz
              </Button>
            </Col>
          </Row>
        </PageHeader>
      )}
    </div>
  );
};

export default MassTransactions;
