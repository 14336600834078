/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../components/Icon';

export const BankAccountsBox = ({ bankAccounts }) => {
  return (
    <div className='premises-show-box owner-box'>
      <h2>
        <Icon name='dolar' />
        Rachunki do wpłat
      </h2>
      <div className='content'>
        <Row gutter={20} style={{ marginBottom: 20 }}>
          <Col xs={24}>
            <div className='owner-box-content'>
              <div className='item' style={{ flex: 2 }}>
                Podstawowy
              </div>
              <div className='item' style={{ flex: 3 }}>
                {bankAccounts?.BASIC ? bankAccounts?.BASIC : '-'}
              </div>
              <div className='item' style={{ color: '#A7A7FA' }}>
                Indywidualny
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={20} style={{ marginBottom: 20 }}>
          <Col xs={24}>
            <div className='owner-box-content'>
              <div className='item' style={{ flex: 2 }}>
                Fundusz remontowy
              </div>
              <div className='item' style={{ flex: 3 }}>
                {bankAccounts?.RENOVATION ? bankAccounts?.RENOVATION : '-'}
              </div>
              <div className='item' style={{ color: '#A7A7FA' }}>
                Domyślny
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={20} style={{ marginBottom: 20 }}>
          <Col xs={24}>
            <div className='owner-box-content'>
              <div className='item' style={{ flex: 2 }}>
                Miejsce postojowe
              </div>
              <div className='item' style={{ flex: 3 }}>
                {bankAccounts?.PARKING ? bankAccounts?.PARKING : '-'}
              </div>
              <div className='item' style={{ color: '#A7A7FA' }}>
                Domyślny
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
