import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageHeader, Button, Tooltip, message } from 'antd';
import MessagePopup from '../../../components/MessagePopup';
import { Icon } from '../../../components';
import Datagrid from '../../../components/Datagrid';
import Spin from '../../../components/Spin';
import settlementTemplatesService from '../../../services/settlementTemplatesService';
import ActivateTemplate from '../components/ActivateTemplate';
import SettlementTemplate from '../components/SettlementTemplate';
import settlementTemplatesCostsService from '../../../services/settlementTemplatesCostsService';
import settlementTemplatesGroupsService from '../../../services/settlementTemplatesGroupsService';
import housingSettlementsService from '../../../services/housingSettlementsService';
import premisesSettlementsService from '../../../services/premisesSettlementsService';
import GenerateModal from '../components/GenerateModal';
import premiseTypeMap from '../../../helpers/premiseTypeMap';
import premisesService from '../../../services/premisesService';
import ClientsService from '../../../services/clientsService';
import moment from 'moment';

const RenderStatus = (status) => {
  if (status === 'TO_PAY') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Do zapłaty'}>
          <span className={'dot red'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PARTIALLY_PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Częściowo zapłacone'}>
          <span className={'dot yellow'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Zapłacone'}>
          <span className={'dot green'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  }
};

const getRowClassName = (record, index) => {
  if (record.status === 'TO_PAY') {
    return 'datagrid-row-red';
  } else if (record.status === 'PARTIALLY_PAID') {
    return 'datagrid-row-yellow';
  }

  return '';
};

const List = ({ userData }) => {
  const { id } = useParams();
  const history = useHistory();
  const datagridRef = useRef();
  const [loading, setLoading] = useState(true);
  const [settlementData, setSettlementData] = useState();
  const [settlementGroups, setSettlementGroups] = useState([]);
  const [settlementCosts, setSettlementCosts] = useState([]);
  const [generateModalVisible, setGenerateModalVisible] = useState(false);
  const [premises, setPremises] = useState([]);
  const [investmentPremises, setInvestmentPremises] = useState([]);
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    ClientsService.getList({
      role: 'ROLE_COMMUNITY_MEMBER',
      size: 9999,
    }).then((data) => {
      setOwners(data.content);
    });
  }, []);

  useEffect(() => {
    const PremisesService = new premisesService(id);
    PremisesService.getList({
      size: 9999,
    }).then((response) => {
      setInvestmentPremises(response.content);
      setPremises(response.content);
    });

    setLoading(false);
  }, [id]);

  useEffect(() => {
    const SettlementTemplatesService = new settlementTemplatesService(id);

    SettlementTemplatesService.getList()
      .then((response) => {
        setSettlementData(response);
      })
      .catch((err) => {});

    setLoading(false);
  }, []);

  useEffect(() => {
    const SettlementTemplatesGroupsService =
      new settlementTemplatesGroupsService(id);

    SettlementTemplatesGroupsService.getList().then((data) => {
      setSettlementGroups(data);
    });
  }, []);

  useEffect(() => {
    setSettlementCosts([]);

    for (let settlementGroup of settlementGroups) {
      const SettlementTemplatesCostsService =
        new settlementTemplatesCostsService(id, settlementGroup.id);

      SettlementTemplatesCostsService.getList().then((data) => {
        setSettlementCosts((curr) => [...curr, ...data]);
      });
    }
  }, [settlementGroups]);

  const columns = [
    {
      title: 'Nazwa rozliczenia',
      dataIndex: 'name',
      visible: true,
      filterEnabled: true,
      filterFullWidth: true,
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Lokal',
      dataIndex: 'premises',
      visible: true,
      filterEnabled: false,
      key: 'premises',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        `${premiseTypeMap(record.premisesType)} ${record.premisesName}`,
      exportRender: (text, record) =>
        `${premiseTypeMap(record.premisesType)} ${record.premisesName}`,
    },
    {
      title: 'Typ lokalu',
      dataIndex: 'premisesType',
      visible: false,
      key: 'premisesType',
      sorter: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        {
          value: 'RENT',
          label: 'Mieszkanie',
        },
        {
          value: 'STORAGE',
          label: 'Komórka lokatorska',
        },
        {
          value: 'PARKING',
          label: 'Miejsce postojowe',
        },
        {
          value: 'SERVICE',
          label: 'Lokal usługowy',
        },
      ],
      onFilterChange: (e) => {
        setPremises(
          investmentPremises.filter((premise) => {
            if (!e) {
              return true;
            } else {
              return premise.type === e;
            }
          })
        );
      },
      export: false,
    },
    {
      title: 'Lokal',
      dataIndex: 'premisesIds',
      visible: false,
      key: 'premisesIds',
      sorter: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: premises.map((premise) => ({
        value: premise.id,
        label: premise.name,
      })),
      export: false,
      requireIfSet: {
        field: 'premisesType',
        message: 'Jeśli wybrałeś typ lokalu wybierz lokale',
      },
    },
    {
      title: 'Dodano',
      dataIndex: 'createDate',
      visible: true,
      filterEnabled: false,
      key: 'createDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        {
          value: 'PAID',
          label: (
            <span>
              <span className={`dot green`} style={{ marginRight: 8 }} />
              Zapłacone
            </span>
          ),
        },
        {
          value: 'PARTIALLY_PAID',
          label: (
            <span>
              <span className={`dot yellow`} style={{ marginRight: 8 }} />
              Częściowo zapłacone
            </span>
          ),
        },
        {
          value: 'TO_PAY',
          label: (
            <span>
              <span className={`dot red`} style={{ marginRight: 8 }} />
              Do zapłaty
            </span>
          ),
        },
      ],
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (text ? RenderStatus(text) : ''),
      exportRender: (text) => {
        if (text) {
          if (text === 'TO_PAY') {
            return 'Do zapłaty';
          } else if (text === 'PARTIALLY_PAID') {
            return 'Częściowo zapłacone';
          }
          if (text === 'PAID') {
            return 'Zapłacone';
          }
        } else {
          return '';
        }
      },
    },
    // {
    //   title: 'Właściciel',
    //   dataIndex: 'owner',
    //   visible: true,
    //   filterEnabled: false,
    //   key: 'owner',
    //   sorter: false,
    //   export: true,
    //   render: (text, record) =>
    //     record.owner
    //       ? `${record.owner.firstName} ${record.owner.lastName}`
    //       : '',
    //   exportRender: (text, record) =>
    //     record.owner
    //       ? `${record.owner.firstName} ${record.owner.lastName}`
    //       : '',
    // },
    {
      title: 'Właściciel',
      dataIndex: 'ownerIds',
      visible: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: owners.map((owner) => ({
        value: owner.id,
        label: [owner.firstName, owner.lastName].filter((e) => !!e).join(' '),
      })),
      key: 'ownerIds',
      sorter: false,
      export: false,
    },
    {
      title: 'Data dodania od',
      dataIndex: 'createDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'createDateFrom',
      export: false,
    },
    {
      title: 'Data dodania do',
      dataIndex: 'createDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'createDateTo',
      export: false,
    },
    {
      title: 'Termin płatności',
      dataIndex: 'paymentDate',
      visible: true,
      filterEnabled: false,
      key: 'paymentDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Termin płatności od',
      dataIndex: 'paymentDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'paymentDateFrom',
      export: false,
    },
    {
      title: 'Termin płatności do',
      dataIndex: 'paymentDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'paymentDateTo',
      export: false,
    },
    {
      title: 'Data opłacenia',
      dataIndex: 'paidDate',
      visible: false,
      filterEnabled: false,
      key: 'paidDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Zapłacono',
      dataIndex: 'paid',
      visible: true,
      filterEnabled: false,
      key: 'paid',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
    {
      title: 'Kwota (brutto)',
      dataIndex: 'price',
      visible: true,
      filterEnabled: false,
      key: 'price',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
  ];

  const testGenerateSettlements = () => {
    setGenerateModalVisible(true);
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          {userData.testAccount && (
            <GenerateModal
              isModalVisible={generateModalVisible}
              afterCancel={() => setGenerateModalVisible(false)}
              id={id}
            />
          )}
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            backIcon={<Icon name='arrow-simply-left' />}
            // title={investment.name}
            title={'Rozliczenia'}
            className='page-header-extra-2'
            extra={[
              ...[
                userData.testAccount && (
                  <Button
                    key='99'
                    type='primary'
                    onClick={() => testGenerateSettlements()}
                  >
                    Generuj rozliczenia
                    <Icon name='plus' />
                  </Button>
                ),
              ],
              <Button
                key='1'
                className='button-secondary'
                style={{ minWidth: 0, maxWidth: 48 }}
                onClick={() => window.print()}
              >
                <Icon name='print' style={{ paddingLeft: 0 }} />
              </Button>,
              <Button
                key='2'
                className='button-secondary'
                onClick={() =>
                  history.push(`/investment/${id}/housingSettlements/import`)
                }
              >
                <Icon name='download' style={{ marginRight: 5 }} />
                Importuj potwierdzenia
              </Button>,
              <Button
                key='3'
                type='primary counters-edit-btn'
                onClick={() =>
                  history.push(
                    settlementData
                      ? `/investment/${id}/housingSettlements/edit`
                      : `/investment/${id}/housingSettlements/create`
                  )
                }
              >
                {settlementData ? 'Edytuj' : 'Dodaj'}
                {settlementData ? <Icon name='edit' /> : <Icon name='plus' />}
              </Button>,
            ]}
          >
            {settlementData && settlementData.active ? (
              <SettlementTemplate settlementData={settlementData} />
            ) : (
              <ActivateTemplate
                settlementData={settlementData}
                setSettlementData={setSettlementData}
              />
            )}
          </PageHeader>
          <Datagrid
            ref={datagridRef}
            title='Lista rozliczeń'
            customBaseUrlKey={'housingSettlements'}
            resource={'housingSettlements'}
            dataProvider={housingSettlementsService}
            columns={columns}
            customRowActions={[
              (record) => {
                if (record.status !== 'PAID') {
                  return (
                    <Icon
                      style={{ cursor: 'pointer' }}
                      name='dolar'
                      className='settlement-transaction-icon'
                      onClick={() => {
                        const PremisesSettlementsService =
                          new premisesSettlementsService(
                            record.investmentId,
                            record.premisesId
                          );
                        const tempData = { ...record };

                        tempData.paid = tempData.price;
                        tempData.paidDate = moment().format('YYYY-MM-DD');

                        PremisesSettlementsService.update(
                          record.id,
                          tempData
                        ).then((response) => {
                          message.success(
                            <MessagePopup type={'success'}>
                              Opłacono
                            </MessagePopup>
                          );
                          datagridRef.current.refresh();
                        });
                      }}
                    />
                  );
                } else {
                  return null;
                }
              },
            ]}
            filters={{
              investmentIds: id,
            }}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            hideCreate
            hideReset
            hideSms
            hideEmail
            hideDelete={!userData.testAccount}
            customRowClass={getRowClassName}
            showDownloadBankTransferPDFForms
          />
        </div>
      )}
    </div>
  );
};

export default List;
