import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  message,
  Card,
  Table,
  Input as AntdInput,
} from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Icon from '../../../../../components/Icon';
import Input from '../../../../../components/InputComponent';
import Spin from '../../../../../components/Spin';
import MessagePopup from '../../../../../components/MessagePopup';

import settlementTemplatesGroupsService from '../../../../../services/settlementTemplatesGroupsService';
import premisesService from '../../../../../services/premisesService';
import clientsService from '../../../../../services/clientsService';

import getPremiseType from '../../../../../helpers/premiseTypeMap';

const { Select, DatePicker, Switch } = Input;
const { Option } = Select;

const useQuery = () => new URLSearchParams(useLocation().search);

const premisesColumns = [
  {
    title: 'Lokal',
    dataIndex: 'premiseName',
    visible: true,
  },
  {
    title: 'Piętro',
    dataIndex: 'floor',
    visible: true,
    render: (text) => (text ? `Piętro ${text}` : ''),
  },
  {
    title: 'Właściciel',
    dataIndex: 'owner',
    visible: true,
  },
];

const floorColumns = [
  {
    title: 'Piętro',
    dataIndex: 'floor',
    visible: true,
    render: (text) => (text ? `Piętro ${text}` : ''),
  },
  {
    title: 'Lokale',
    dataIndex: 'premisesNames',
    visible: true,
    render: (text) => text.join(', '),
  },
];

const membersColumns = [
  {
    title: 'Członkowie',
    dataIndex: 'fullName',
    visible: true,
  },
  {
    title: 'Lokal',
    dataIndex: 'premisesNames',
    visible: true,
  },
];

const Create = () => {
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [withoutExpireDate, setWithoutExpireDate] = useState(false);

  const [premisesList, setPremisesList] = useState([]);
  const [floorsList, setFloorsList] = useState([]);
  const [membersList, setMembersList] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState();

  const query = useQuery();

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchPhrase]);

  useEffect(() => {
    const queryStatus = query.get('status');

    if (queryStatus != null) {
      form.setFieldsValue({
        status: queryStatus,
      });
    }
  }, []);

  useEffect(() => {
    const PremisesService = new premisesService(id);

    PremisesService.getList({
      size: 9999,
    }).then((data) => {
      const tempPremisesList = data?.content?.map((record) => ({
        id: record.id,
        premiseName: [getPremiseType(record.type), record.name]
          .filter((el) => !!el)
          .join(' '),
        floor: record.floor,
        owner: record.premisesOwnerName,
      }));

      setPremisesList(tempPremisesList);

      const tempFloorsList = data?.content?.reduce((acc, curr) => {
        const tempIdx = acc.findIndex((e) => e.floor === curr.floor);

        if (tempIdx > -1) {
          acc[tempIdx].premisesNames.push(
            [getPremiseType(curr.type), curr.name]
              .filter((el) => !!el)
              .join(' ')
          );

          acc[tempIdx].premisesIds.push(curr.id);
        } else {
          acc.push({
            floor: curr.floor,
            premisesNames: [
              [getPremiseType(curr.type), curr.name]
                .filter((el) => !!el)
                .join(' '),
            ],
            premisesIds: [curr.id],
          });
        }

        return acc;
      }, []);

      setFloorsList(tempFloorsList);
    });

    clientsService
      .getList({
        role: 'ROLE_COMMUNITY_MEMBER',
        investmentId: id,
        size: 9999,
      })
      .then((data) => {
        const tempMembersList = data?.content?.map((record) => ({
          id: record.id,
          fullName: [record.lastName, record.firstName]
            .filter((el) => !!el)
            .join(' '),
          premisesNames: record.premisesOwnerList
            ?.map((premise) => [
              [getPremiseType(premise.type), premise.name]
                .filter((el) => !!el)
                .join(' '),
            ])
            .join(', '),
          premisesIds: record.premisesOwnerList.map((premise) => premise.id),
        }));

        setMembersList(tempMembersList);
      });

    setLoading(false);
  }, []);

  const clearSelectedRowIds = useCallback(() => {
    setSelectedRowIds([]);
    setSearchPhrase();
  }, []);

  const selectChangeHandler = useCallback(
    (selectedRowKeys) => {
      setSelectedRowIds(selectedRowKeys);
    },
    [setSelectedRowIds]
  );

  const onFinish = (values) => {
    const { searchPhrase, ...restValues } = values;
    const tempValues = { ...restValues, type: 'INDIVIDUAL' };

    tempValues.withoutExpireDate = withoutExpireDate;

    if (values.dateFrom) {
      tempValues.dateFrom = values.dateFrom.format('YYYY-MM-DD');
    }
    if (values.dateTo) {
      tempValues.dateTo = values.dateTo.format('YYYY-MM-DD');
    }

    if (values.individualType === 'PREMISES') {
      if (selectedRowIds.length === 0) {
        message.error(
          <MessagePopup type='error'>
            Musisz wybrać co najmniej jeden lokal
          </MessagePopup>
        );
        return;
      }

      const selectedPremises = premisesList.filter((el) =>
        selectedRowIds.includes(el.id)
      );
      tempValues.premisesIds = selectedPremises.map((premise) => premise.id);
    } else if (values.individualType === 'FLOOR') {
      if (selectedRowIds.length === 0) {
        message.error(
          <MessagePopup type='error'>
            Musisz wybrać co najmniej jedno piętro
          </MessagePopup>
        );
        return;
      }

      const selectedFloors = floorsList.filter((el) =>
        selectedRowIds.includes(el.floor)
      );
      tempValues.premisesIds = selectedFloors.reduce(
        (acc, curr) => acc.concat(curr.premisesIds),
        []
      );
    } else if (values.individualType === 'MEMBERS') {
      if (selectedRowIds.length === 0) {
        message.error(
          <MessagePopup type='error'>
            Musisz wybrać co najmniej jednego członka
          </MessagePopup>
        );
        return;
      }

      const selectedMembers = membersList.filter((el) =>
        selectedRowIds.includes(el.id)
      );

      tempValues.premisesIds = selectedMembers.reduce(
        (acc, curr) => acc.concat(curr.premisesIds),
        []
      );
    }

    const SettlementTemplatesGroupsService =
      new settlementTemplatesGroupsService(id);

    SettlementTemplatesGroupsService.create(tempValues)
      .then((response) => {
        history.replace(
          `/investment/${id}/housingSettlements/individualCostsGroups/${response.id}/edit`
        );
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    history.replace(
      `/investment/${id}/housingSettlements/edit?tab=individualCostsGroups`
    );
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() =>
            history.replace(
              `/investment/${id}/housingSettlements/edit?tab=individualCostsGroups`
            )
          }
          title='Dodaj koszt indywidualny'
        >
          <Form
            name='create'
            className='create-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ repeatability: 'MONTH' }}
            autoComplete='off'
            form={form}
          >
            <Card>
              <div className='create-form-wrapper'>
                <Row>
                  <Col
                    xs={{ offset: 1, span: 22 }}
                    lg={{ offset: 4, span: 16 }}
                  >
                    <div className='form-section'>
                      <h2>Podstawowe</h2>
                      <Row gutter={20}>
                        <Col xs={24}>
                          <Form.Item
                            name='name'
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić nazwę',
                              },
                            ]}
                          >
                            <Input size='large' placeholder='Nazwa' />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Form.Item
                            name='repeatability'
                            rules={[
                              {
                                required: true,
                                message: 'Wybierz co ile powtarzać koszt',
                              },
                            ]}
                          >
                            <Select size='large' placeholder='Powtarzaj'>
                              <Option value='MONTH'>Co 1 miesiąc</Option>
                              <Option value='TWO_MONTHS'>Co 2 miesiące</Option>
                              <Option value='THREE_MONTHS'>
                                Co 3 miesiące
                              </Option>
                              <Option value='HALF_YEAR'>Co 6 miesięcy</Option>
                              <Option value='YEAR'>Co 12 miesięcy</Option>
                              {/* <Option value='TWO_YEAR'>Co 2 lata</Option> */}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Form.Item
                            name='dateFrom'
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić piętro',
                              },
                            ]}
                          >
                            <DatePicker
                              size='large'
                              placeholder='Data od'
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Form.Item
                            name='dateTo'
                            rules={
                              !withoutExpireDate && [
                                {
                                  required: true,
                                  message: 'To pole jest wymagane',
                                },
                              ]
                            }
                          >
                            <DatePicker
                              size='large'
                              placeholder='Data do'
                              style={{ width: '100%' }}
                              disabled={withoutExpireDate}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Form.Item
                            name='withoutExpireDate'
                            valuePropName='checked'
                          >
                            <span
                              style={{
                                marginLeft: 20,
                                marginRight: 50,
                                fontSize: 16,
                              }}
                            >
                              Do odwołania
                            </span>
                            <Switch
                              onChange={(checked) => {
                                setWithoutExpireDate(checked);
                                if (checked) {
                                  form.resetFields(['dateTo']);
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>

                    <Row gutter={[20, 15]}>
                      <Col xs={24} lg={12}>
                        <div className='form-section'>
                          <h2>Rozliczenia</h2>
                          <Row gutter={20}>
                            <Col xs={24} key='premisesTypes'>
                              <Form.Item
                                name='fund'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Select
                                  placeholder='Rachunek do wpłat'
                                  size='large'
                                >
                                  <Option value='BASIC'>Podstawowy</Option>
                                  <Option value='RENOVATION'>
                                    Fundusz remontowy
                                  </Option>
                                  <Option value='PARKING'>
                                    Miejsce postojowe
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xs={24} lg={12}>
                        <div className='form-section'>
                          <h2>Odbiorcy</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='individualType'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Select
                                  placeholder='Przypisz do'
                                  size='large'
                                  onChange={clearSelectedRowIds}
                                >
                                  <Option value='PREMISES'>
                                    Wybrane lokale
                                  </Option>
                                  <Option value='FLOOR'>Wybrane piętra</Option>
                                  <Option value='MEMBERS'>
                                    Wybrani członkowie
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col xs={24}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) =>
                            prev.individualType !== current.individualType
                          }
                        >
                          {({ getFieldValue }) => {
                            const individualType =
                              getFieldValue('individualType');
                            if (individualType === 'PREMISES') {
                              return (
                                <div className='messages-select-ids-table-wrapper'>
                                  <Table
                                    dataSource={
                                      searchPhrase
                                        ? premisesList.filter((premise) =>
                                            premise.premiseName
                                              ?.toLowerCase()
                                              .includes(
                                                searchPhrase.toLowerCase()
                                              )
                                          )
                                        : premisesList
                                    }
                                    rowKey={(record) => record.id}
                                    rowSelection={{
                                      selectedRowKeys: selectedRowIds,
                                      onChange: selectChangeHandler,
                                    }}
                                    columns={premisesColumns}
                                    pagination={false}
                                    components={{
                                      header: {
                                        wrapper: (props) => {
                                          return (
                                            <thead className={props.className}>
                                              {props.children}
                                              <tr>
                                                <td
                                                  colSpan={
                                                    premisesColumns.length + 1
                                                  }
                                                  style={{
                                                    fontWeight: 'bold',
                                                    borderLeft:
                                                      '1px solid #c0d6e5',
                                                    borderRight:
                                                      '1px solid #c0d6e5',
                                                    padding: 20,
                                                    paddingBottom: 0,
                                                  }}
                                                >
                                                  <Form.Item
                                                    name='searchPhrase'
                                                    value={searchPhrase}
                                                  >
                                                    <AntdInput
                                                      ref={inputRef}
                                                      size='large'
                                                      placeholder='Szukaj'
                                                      prefix={
                                                        <Icon
                                                          name='search'
                                                          style={{
                                                            fontSize: '24px',
                                                            marginBottom: 3,
                                                            marginLeft: 8,
                                                            color: '#C0D6E5',
                                                          }}
                                                        />
                                                      }
                                                      onChange={(event) => {
                                                        setSearchPhrase(
                                                          event.target.value
                                                        );
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </td>
                                              </tr>
                                            </thead>
                                          );
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              );
                            } else if (individualType === 'FLOOR') {
                              return (
                                <div className='messages-select-ids-table-wrapper'>
                                  <Table
                                    dataSource={
                                      searchPhrase
                                        ? floorsList.filter((floor) =>
                                            `Piętro ${floor.floor}`
                                              .toLowerCase()
                                              .includes(
                                                searchPhrase.toLowerCase()
                                              )
                                          )
                                        : floorsList
                                    }
                                    rowKey={(record) => record.floor}
                                    rowSelection={{
                                      selectedRowKeys: selectedRowIds,
                                      onChange: selectChangeHandler,
                                    }}
                                    columns={floorColumns}
                                    pagination={false}
                                    components={{
                                      header: {
                                        wrapper: (props) => {
                                          return (
                                            <thead className={props.className}>
                                              {props.children}
                                              <tr>
                                                <td
                                                  colSpan={
                                                    premisesColumns.length + 1
                                                  }
                                                  style={{
                                                    fontWeight: 'bold',
                                                    borderLeft:
                                                      '1px solid #c0d6e5',
                                                    borderRight:
                                                      '1px solid #c0d6e5',
                                                    padding: 20,
                                                    paddingBottom: 0,
                                                  }}
                                                >
                                                  <Form.Item
                                                    name='searchPhrase'
                                                    value={searchPhrase}
                                                  >
                                                    <AntdInput
                                                      ref={inputRef}
                                                      size='large'
                                                      placeholder='Szukaj'
                                                      prefix={
                                                        <Icon
                                                          name='search'
                                                          style={{
                                                            fontSize: '24px',
                                                            marginBottom: 3,
                                                            marginLeft: 8,
                                                            color: '#C0D6E5',
                                                          }}
                                                        />
                                                      }
                                                      onChange={(event) => {
                                                        setSearchPhrase(
                                                          event.target.value
                                                        );
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </td>
                                              </tr>
                                            </thead>
                                          );
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              );
                            } else if (individualType === 'MEMBERS') {
                              return (
                                <div className='messages-select-ids-table-wrapper'>
                                  <Table
                                    dataSource={
                                      searchPhrase
                                        ? membersList.filter((member) =>
                                            member.fullName
                                              .toLowerCase()
                                              .includes(
                                                searchPhrase.toLowerCase()
                                              )
                                          )
                                        : membersList
                                    }
                                    rowKey={(record) => record.id}
                                    rowSelection={{
                                      selectedRowKeys: selectedRowIds,
                                      onChange: selectChangeHandler,
                                    }}
                                    columns={membersColumns}
                                    pagination={false}
                                    components={{
                                      header: {
                                        wrapper: (props) => {
                                          return (
                                            <thead className={props.className}>
                                              {props.children}
                                              <tr>
                                                <td
                                                  colSpan={
                                                    premisesColumns.length + 1
                                                  }
                                                  style={{
                                                    fontWeight: 'bold',
                                                    borderLeft:
                                                      '1px solid #c0d6e5',
                                                    borderRight:
                                                      '1px solid #c0d6e5',
                                                    padding: 20,
                                                    paddingBottom: 0,
                                                  }}
                                                >
                                                  <Form.Item
                                                    name='searchPhrase'
                                                    value={searchPhrase}
                                                  >
                                                    <AntdInput
                                                      ref={inputRef}
                                                      size='large'
                                                      placeholder='Szukaj'
                                                      prefix={
                                                        <Icon
                                                          name='search'
                                                          style={{
                                                            fontSize: '24px',
                                                            marginBottom: 3,
                                                            marginLeft: 8,
                                                            color: '#C0D6E5',
                                                          }}
                                                        />
                                                      }
                                                      onChange={(event) => {
                                                        setSearchPhrase(
                                                          event.target.value
                                                        );
                                                      }}
                                                    />
                                                  </Form.Item>
                                                </td>
                                              </tr>
                                            </thead>
                                          );
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              );
                            }
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Dodaj
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
