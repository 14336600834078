import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Button, PageHeader, Row, Col, Card, message } from 'antd';
import moment from 'moment';

import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';

import transactionsService from '../../services/transactionsService';
import MessagePopup from '../../components/MessagePopup';

const { DatePicker, InputNumberV2 } = Input;

const Edit = () => {
  const history = useHistory();
  const { memberId, transactionId } = useParams();
  const [form] = Form.useForm();
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    const TransactionsService = new transactionsService(memberId);

    TransactionsService.getOne(transactionId).then((data) => {
      const tempData = {
        ...data,
        transactionDate: data.transactionDate
          ? moment(data.transactionDate)
          : null,
      };

      form.setFieldsValue(tempData);
      setTransaction(tempData);
    });
  }, [memberId, transactionId, form]);

  const onFinish = (values) => {
    const TransactionsService = new transactionsService(memberId);

    const tempValues = {
      ...values,
      transactionDate: values.transactionDate.format('YYYY-MM-DD'),
    };

    TransactionsService.update(transactionId, tempValues)
      .then((response) => {
        history.replace(`/members/${memberId}/transactions`);
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const HeaderTitle = () => {
    if (!transaction.title) {
      return <span>Edytujesz transakcję</span>;
    }
    return (
      <span>
        Edytujesz transakcję:{' '}
        <span className='header-subtitle'>{transaction.title}</span>
      </span>
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className='form-create'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => window.history.back()}
        title={<HeaderTitle />}
      >
        <Form
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          form={form}
          initialValues={{
            transactionDate: moment(),
          }}
        >
          <Card>
            <div className='create-form-wrapper'>
              <Row>
                <Col xs={16} offset={4}>
                  <div className='form-section'>
                    <h2>Podstawowe</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='title'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <Input size='large' placeholder='Nazwa operacji' />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item name='transactionDate'>
                          <DatePicker
                            size='large'
                            placeholder='Data dodania'
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div className='form-section'>
                    <h2>Płatności</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='price'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę podać kwotę',
                            },
                          ]}
                        >
                          <InputNumberV2
                            size='large'
                            placeholder='Kwota'
                            addonAfter={'zł brutto'}
                            allowNegative
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Row className='form-actions' justify='space-between'>
            <Col sm={4} className='form-actions-cancel-wrapper'>
              <Button
                className='button-secondary'
                size='large'
                onClick={handleCancel}
              >
                Anuluj
              </Button>
            </Col>
            <Col sm={4} className='form-actions-save-wrapper'>
              <Form.Item>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  className='create-form-button'
                >
                  Zapisz
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
