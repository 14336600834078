import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Button, PageHeader, Row, Col, Card, message } from 'antd';

import moment from 'moment';
import PremisesServiceS from '../../../../services/premisesService';
import investmentsService from '../../../../services/investmentsService';
import Icon from '../../../../components/Icon';
import deleteConfirm from '../../../../components/deleteConfirm';

import Input from '../../../../components/InputComponent';
import Tabs from '../../../../components/Tabs';
import CountrySelect from '../../../../components/CountrySelect';
import MessagePopup from '../../../../components/MessagePopup';
import { Attachments } from '../grids/Attachments';
import { Faults } from '../grids/Faults';
import { Contacts } from '../grids/Contacts';
import { OwnerList } from '../grids/OwnerList';

const { Select, InputNumberV2, InputNumberInt, TextArea, DatePicker, Switch } =
  Input;
const { Option } = Select;
const { TabPane } = Tabs;

const Edit = () => {
  const { id, premisesId } = useParams();

  const [premisesData, setPremisesData] = useState({});
  const [currentStatus, setCurrentStatus] = useState('');
  const [investment, setInvestment] = useState({});

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    const PremisesService = new PremisesServiceS(id);
    PremisesService.getOne(premisesId).then((data) => {
      const formData = data;
      if (data.soldStatusDate) {
        formData.soldStatusDate = moment(formData.soldStatusDate, 'YYYY-MM-DD');
      }
      setPremisesData(data);
      setCurrentStatus(data.status);
      if (data.geographicDirections === null) {
        formData.geographicDirections = undefined;
      }
      form.setFieldsValue(formData);
    });

    const InvestmentsService = new investmentsService();
    InvestmentsService.getOne(id).then((data) => {
      setInvestment(data);
    });
  }, [id, form, premisesId]);

  const refresh = () => {
    const PremisesService = new PremisesServiceS(id);
    PremisesService.getOne(premisesId).then((data) => {
      setPremisesData(data);
    });
  };

  const onFinish = (values) => {
    if (values?.soldStatusDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        soldStatusDate: values.soldStatusDate.format('YYYY-MM-DD'),
      };
    }

    const data = {
      ...premisesData,
      ...values,
    };

    const PremisesService = new PremisesServiceS(id);
    PremisesService.update(premisesId, data)
      .then((response) => {
        if (response.id) {
          history.replace(
            `/housingAssociations/${id}/premises/${response.id}/show`
          );
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    const PremisesService = new PremisesServiceS(id);
    PremisesService.delete(premisesId)
      .then(() => history.push(`/housingAssociations/${id}/premises`))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const handleCancel = () => {
    // history.push(`/housingAssociations/${id}/premises`);
    history.replace(`/housingAssociations/${id}/premises/${premisesId}/show`);
  };

  const handleStatusChange = (status) => {
    setCurrentStatus(status);
  };

  const HeaderTitle = () => {
    if (!premisesData.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz: <span className='header-subtitle'>{premisesData.name}</span>
      </span>
    );
  };

  const onFieldsChange = (fieldChanged) => {
    const fieldChangedNames = fieldChanged
      .map((field) => field.name)
      .reduce((a, b) => a.concat(b));
    if (fieldChangedNames.includes('investmentAddress')) {
      const investmentAddress = form.getFieldValue('investmentAddress');
      if (investmentAddress) {
        form.setFieldsValue({
          address: investment.address,
        });
      } else {
        form.setFieldsValue({
          address: premisesData.address,
        });
      }
    }
  };

  const floorOptions = [];
  for (let index = -5; index <= 20; index += 1) {
    floorOptions.push(<Option value={index}>{index}</Option>);
  }

  const canShowLocal =
    premisesData.type !== 'STORAGE' && premisesData.type !== 'PARKING';

  return (
    <div className='form-create premises-edit'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => window.history.back()}
        title={<HeaderTitle />}
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <h2>Dane podstawowe</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='name'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić nazwę',
                                  },
                                ]}
                              >
                                <Input
                                  size='large'
                                  placeholder='Numer lokalu'
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='floor'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić piętro',
                                  },
                                ]}
                              >
                                <Select placeholder='Piętro' size='large'>
                                  {floorOptions}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='soldStatusDate'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <DatePicker
                                  size='large'
                                  placeholder='Data przekazania'
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={12}>
                              <Form.Item name='geographicDirections'>
                                <Select
                                  placeholder='Strony świata'
                                  size='large'
                                  mode='multiple'
                                >
                                  <Option value='Północ'>Północ</Option>
                                  <Option value='Południe'>Południe</Option>
                                  <Option value='Wschód'>Wschód</Option>
                                  <Option value='Zachód'>Zachód</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <h2>Powierzchnia całkowita</h2>
                              <Form.Item
                                name='totalSurface'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                                style={{ marginBottom: 0 }}
                              >
                                <InputNumberV2
                                  size='large'
                                  placeholder='Powierzchnia'
                                  addonAfter={
                                    <>
                                      m<sup>2</sup>
                                    </>
                                  }
                                />
                              </Form.Item>
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#C0D6E5',
                                }}
                              >
                                * Według aktu notarialnego.
                              </div>
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#C0D6E5',
                                }}
                              >
                                ** W tym: pokoje, kuchnia, korytarz, toaleta,
                                itp.
                              </div>
                            </Col>
                            <Col xs={24} sm={12}>
                              <h2>Lokatorzy</h2>
                              <Form.Item
                                name='tenantsCount'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                                style={{ marginBottom: 0 }}
                              >
                                <InputNumberInt
                                  size='large'
                                  placeholder='Ilość lokatorów'
                                />
                              </Form.Item>
                              <div
                                style={{
                                  fontSize: '12px',
                                  color: '#C0D6E5',
                                }}
                              >
                                * Ilość osób mieszkających w lokalu, wraz z
                                najemcami.
                              </div>
                            </Col>
                            <Col xs={24} sm={12}>
                              <h2>Śmieci z wody</h2>
                              <Form.Item
                                  name='garbageWater'
                                  style={{marginBottom: 0}}
                              >
                                <InputNumberV2
                                    size='large'
                                    placeholder='Stawka'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Adres</h2>
                          <div className='premise-investment-address'>
                            <Row gutter={20}>
                              <Col xs={12}>Pobierz adres z inwestycji</Col>
                              <Col xs={12} style={{ textAlign: 'right' }}>
                                <Form.Item
                                  name='investmentAddress'
                                  valuePropName='checked'
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'number']}>
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'local']}>
                                <Input size='large' placeholder='Lokal' />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Notatka</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item name='comment'>
                                <TextArea rows={4} placeholder='Notatka' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab='Właściciel' key='owner'>
                  <OwnerList />
                </TabPane>
                <TabPane tab='Dodatkowe kontakty' key='additionalContacts'>
                  <Contacts investmentId={id} premisesId={premisesId} />
                </TabPane>
                {/* <TabPane tab='Usterki' key='faults'>
                  <Faults investmentId={id} premisesId={premisesId} />
                </TabPane> */}
                <TabPane tab='Załączniki' key='attachments'>
                  <Attachments investmentId={id} premisesId={premisesId} />
                </TabPane>
              </Tabs>
              <Button
                className='delete-btn'
                type='danger'
                onClick={() => deleteConfirm(handleOk)}
              >
                <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
              </Button>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col xs={11} sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col xs={11} sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
