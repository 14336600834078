import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import Footer from '../../../../../../helpers/Footer';
import Input from '../../../../../../components/InputComponent';
import MessagePopup from '../../../../../../components/MessagePopup';
import mainCountersService from '../../../../../../services/mainCountersService';
import settlementTemplatesCostsService from '../../../../../../services/settlementTemplatesCostsService';

const { InputNumberV2, Select } = Input;
const { Option } = Select;

const CostsModal = (props) => {
  const { afterCancel, isModalVisible, editingCost } = props;
  const [form] = Form.useForm();
  const { id, groupId } = useParams();
  const [mainCounters, setMainCounters] = useState([]);
  const [mainCountersOptions, setMainCountersOptions] = useState([]);
  const [useCounterName, setUseCounterName] = useState();
  const [useCounterUnit, setUseCounterUnit] = useState();

  useEffect(() => {
    const MainCountersService = new mainCountersService(id);

    MainCountersService.getList().then((data) => {
      setMainCounters(data.content);
    });
  }, []);

  useEffect(() => {
    const tempOptions = [
      { mainCounterId: null, isMainCounterDifference: null },
    ];

    for (let counter of mainCounters) {
      tempOptions.push({
        mainCounterId: counter?.id,
        isMainCounterDifference: false,
        counterNumber: counter?.number,
        counterName: counter?.name,
        counterUnit: counter?.unit,
      });
      // if (counter?.unit !== 'kwh') {
      tempOptions.push({
        mainCounterId: counter?.id,
        isMainCounterDifference: true,
        counterNumber: counter?.number,
        counterName: counter?.name,
        counterUnit: counter?.unit,
      });
      // }
    }

    setMainCountersOptions(tempOptions);
  }, [mainCounters]);

  useEffect(() => {
    if (editingCost?.mainCounterId) {
      setUseCounterName(editingCost.name);
      setUseCounterUnit(editingCost.unit);
    }

    if (editingCost) {
      const tempData = {
        name: editingCost.name,
        price: editingCost.price,
        type: editingCost.type,
      };

      const counterOptionIdx = mainCountersOptions.findIndex(
        (option) =>
          option.mainCounterId === editingCost.mainCounterId &&
          option.isMainCounterDifference === editingCost.isMainCounterDifference
      );
      tempData.counterId = counterOptionIdx;

      form.setFieldsValue(tempData);
    }
  }, [editingCost]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const SettlementTemplatesCostsService =
          new settlementTemplatesCostsService(id, groupId);

        const tempValues = {
          settlementTemplateGroupId: groupId,
          name: values.name,
          price: values.price,
          // type: values.type,
        };

        if (!values.counterId) {
          tempValues.type = values.type;
          tempValues.unit =
            values.type === 'PERSON'
              ? 'osoba'
              : values.type === 'PREMISES'
              ? 'm2'
              : values.type === 'FIXED_PRICE'
              ? 'Opłata stała'
              : values.type === 'WATER_GARBAGE'
              ? 'Śmieci z wody'
              : null;
        } else {
          tempValues.type = 'METER';
          tempValues.unit = mainCountersOptions[values.counterId].counterUnit;

          tempValues.mainCounterId =
            mainCountersOptions[values.counterId].mainCounterId;
          tempValues.isMainCounterDifference =
            mainCountersOptions[values.counterId].isMainCounterDifference;
        }

        if (editingCost) {
          SettlementTemplatesCostsService.update(editingCost.id, tempValues)
            .then((response) => {
              form.resetFields();
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        } else {
          SettlementTemplatesCostsService.create(tempValues)
            .then((response) => {
              form.resetFields();
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={
          editingCost ? (
            <span>
              Edytujesz: <span className='modal-title'>{editingCost.name}</span>
            </span>
          ) : (
            'Dodaj pozycję'
          )
        }
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={
          <Footer onReset={onReset} onFinish={onFinish} isNew={!editingCost} />
        }
      >
        <Form
          form={form}
          name='filterMember'
          className='filters-developer-form'
          onFinish={onFinish}
          initialValues={{ counterId: 0 }}
        >
          <Row gutter={20}>
            <Col xs={24} lg={12}>
              <Form.Item
                name='counterId'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Select
                  size='large'
                  placeholder='Licznik główny'
                  onSelect={(e) => {
                    setUseCounterName(mainCountersOptions[e].counterName);
                    const costName = mainCountersOptions[e]
                      .isMainCounterDifference
                      ? `${mainCountersOptions[e].counterName} - część wspólna`
                      : mainCountersOptions[e].counterName;
                    form.setFieldValue('name', costName);
                    form.setFieldValue(
                      'unit',
                      mainCountersOptions[e].counterUnit
                    );
                    setUseCounterUnit(mainCountersOptions[e].counterUnit);
                    if (mainCountersOptions[e].mainCounterId) {
                      form.setFieldValue('type', 'METER');
                    } else {
                      form.setFieldValue('type', null);
                    }
                  }}
                >
                  {mainCountersOptions.map((option, idx) => (
                    <Option value={idx}>
                      {option.mainCounterId
                        ? `${option?.counterNumber} (${option?.counterName})`
                        : 'Bez licznika głównego'}{' '}
                      {option.isMainCounterDifference
                        ? '- Różnica w odczycie'
                        : ''}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input
                  size='large'
                  placeholder='Nazwa'
                  // disabled={!!useCounterName}
                />
              </Form.Item>
              <Form.Item hidden name='unit'></Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                name='type'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Select
                  size='large'
                  placeholder='Jednostka'
                  disabled={!!useCounterUnit}
                  onSelect={(e) => {
                    form.setFieldValue('unit', e);
                  }}
                >
                  <Option value='PERSON'>osoba</Option>
                  <Option value='PREMISES'>m2</Option>
                  <Option value='FIXED_PRICE'>opłata stała</Option>
                  <Option value='WATER_GARBAGE'>Śmieci z wody</Option>

                  {useCounterUnit && (
                    // <Option value='METER'>jednostka licznika</Option>
                    <Option value='METER'>
                      {useCounterUnit === 'm3' ? (
                        <span>
                          m<sup>3</sup>
                        </span>
                      ) : (
                        useCounterUnit
                      )}
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name='price'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2
                  size='large'
                  placeholder='Kwota jednostkowa'
                  addonAfter={'zł brutto'}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CostsModal;
