/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Input as AntdInput,
  DatePicker,
  Select,
  InputNumber,
  Spin,
  Switch,
  Slider,
} from 'antd';
import debounce from 'lodash/debounce';
import MaskedInput from 'antd-mask-input';

import FloatLabel from './FloatingLabel';
import Empty from './Empty';

const { TextArea } = AntdInput;

const Input = (props) => {
  const { label, value, id, placeholder } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <AntdInput {...props} placeholder='' />
    </FloatLabel>
  );
};

Input.Password2 = (props) => {
  const { label, value, id, placeholder } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <AntdInput.Password {...props} type='password' placeholder='' />
    </FloatLabel>
  );
};

Input.Slider = (props) => {
  const { label, id, onChangeValue } = props;
  let { value } = props;

  return (
    <>
      <Slider
        defaultValue={value}
        value={value}
        onChange={onChangeValue}
        max={50}
        trackStyle={{ backgroundColor: '#3B4C60', borderRadius: 30, height: 4 }}
        railStyle={{ backgroundColor: '#C0D6E5', borderRadius: 30, height: 4 }}
        handleStyle={{ backgroundColor: '#15D55E', border: 0 }}
        tooltip={{ formatter: (e) => `${label}: ${value}` }}
      />
    </>
  );
};

Input.Password = (props) => {
  const { label, value, id, placeholder } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <AntdInput {...props} type='password' placeholder='' />
    </FloatLabel>
  );
};

Input.MaskedInput = (props) => {
  const { label, value, id, placeholder } = props;
  const customLabel = label || placeholder || '';

  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <MaskedInput {...props} placeholder='' />
    </FloatLabel>
  );
};

Input.DatePicker = (props) => {
  const { label, value, id, placeholder, defaultValue } = props;
  const customLabel = label || placeholder || '';

  return (
    <FloatLabel
      label={customLabel}
      name={id}
      value={value}
      defaultValue={defaultValue}
    >
      <DatePicker
        getPopupContainer={(trigger) => trigger.parentNode.parentNode}
        popupStyle={{ zIndex: 2 }}
        {...props}
        placeholder=''
      />
    </FloatLabel>
  );
};

Input.Select = (props) => {
  const { label, value, id, placeholder, labelClass } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel
      label={customLabel}
      name={id}
      value={value}
      labelClass={labelClass}
    >
      <Select
        optionFilterProp={'label'}
        getPopupContainer={(trigger) => trigger.parentNode.parentNode}
        dropdownStyle={{ zIndex: 2 }}
        popupClassName='select-dropdown'
        {...props}
        placeholder=''
      />
    </FloatLabel>
  );
};

Input.TextArea = (props) => {
  const { label, value, id, placeholder } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <TextArea {...props} placeholder='' />
    </FloatLabel>
  );
};

Input.InputNumber = (props) => {
  const { label, value, id, placeholder } = props;
  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <InputNumber
        {...props}
        placeholder=''
        type='number'
        style={{ width: '100%' }}
        onKeyDown={(evt) =>
          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
        }
      />
    </FloatLabel>
  );
};

Input.InputNumberV2 = (props) => {
  const { label, value, id, placeholder, allowNegative = false } = props;

  const customLabel = label || placeholder || '';

  const convertValueToDecimal = (value) => {
    if (!value) {
      return value;
    }

    let tempValue = value;
    let isNegative = false;

    if (allowNegative) {
      const valueString = value.toString();
      if (valueString.includes('-')) {
        isNegative = true;
      }

      tempValue = valueString.replace('-', '');
    }

    try {
      const wholePlaces = tempValue
        ? tempValue.includes('.')
          ? tempValue?.split('.')[0].length
          : tempValue?.length
        : 0;

      const decimalPlaces = tempValue
        ? tempValue.includes('.')
          ? tempValue.split('.')[1]?.length < 3
            ? tempValue.split('.')[1]?.length + 1
            : 3
          : 0
        : 0;

      const absValue = tempValue.substring(0, wholePlaces + decimalPlaces);

      if (isNegative) {
        return '-' + absValue;
      }

      return absValue;
    } catch (err) {
      return value;
    }
  };

  const inputValue = convertValueToDecimal(value);

  return (
    <FloatLabel label={customLabel} name={id} value={inputValue}>
      <Input
        {...props}
        value={inputValue}
        type='number'
        placeholder=''
        step='0.01'
        min={allowNegative ? null : 0}
        style={{ width: '100%' }}
        onKeyDown={(evt) =>
          allowNegative
            ? (['e', 'E', '+'].includes(evt.key) ||
                (evt.key === '-' && inputValue?.includes('-'))) &&
              evt.preventDefault()
            : ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
        }
      />
    </FloatLabel>
  );
};

Input.InputNumberV2wNegative = (props) => {
  const { label, value, id, placeholder } = props;

  const customLabel = label || placeholder || '';

  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Input
        {...props}
        value={value}
        placeholder=''
        style={{ width: '100%' }}
        onKeyDown={(evt) => {
          const reg = /^-?(0|[1-9][0-9]*)([.,][0-9]{0,2})?$/;
          const currValue = evt.target.value;
          let newValue;

          if (
            ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(
              evt.key
            )
          ) {
            return;
          }

          if (currValue) {
            if (['Backspace', 'Delete'].includes(evt.key)) {
              newValue =
                currValue.slice(0, evt.target.selectionStart) +
                currValue.slice(evt.target.selectionEnd, currValue.length);
            } else {
              newValue =
                currValue.slice(0, evt.target.selectionStart) +
                evt.key +
                currValue.slice(evt.target.selectionEnd, currValue.length);
            }
          } else {
            newValue = evt.key;
          }

          if (!reg.test(newValue)) {
            evt.preventDefault();
          }
        }}
      />
    </FloatLabel>
  );
};

Input.InputNumberInt = (props) => {
  const { label, value, id, placeholder } = props;

  const customLabel = label || placeholder || '';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Input
        {...props}
        type='number'
        placeholder=''
        style={{ width: '100%' }}
        min={0}
        onKeyDown={(evt) =>
          ['e', 'E', '+', '-', ',', '.'].includes(evt.key) &&
          evt.preventDefault()
        }
      />
    </FloatLabel>
  );
};

Input.Mobile = (props) => {
  const { label, value, id, placeholder } = props;

  const customLabel = label || placeholder || '';

  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Input
        {...props}
        placeholder=''
        style={{ width: '100%' }}
        maxLength={15}
        onKeyDown={(evt) => {
          ![
            ...'0123456789',
            'Backspace',
            'Delete',
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown',
            'F12',
            'Tab',
          ].includes(evt.key) && evt.preventDefault();
        }}
      />
    </FloatLabel>
  );
};

Input.PriceInput = (props) => {
  const { label, value, id, placeholder, suffix } = props;

  const customLabel = label || placeholder || '';
  const inputSuffix = suffix || 'zł brutto';
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Input
        {...props}
        type='number'
        suffix={inputSuffix}
        placeholder=''
        step='0.01'
        style={{ width: '100%' }}
      />
    </FloatLabel>
  );
};

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
  const { label, value, id, placeholder } = props;
  const customLabel = label || placeholder || '';
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (val) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(val).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  React.useEffect(() => {
    setOptions([]);
    setFetching(true);
    fetchOptions().then((newOptions) => {
      setOptions(newOptions);
      setFetching(false);
    });
  }, [fetchOptions]);
  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Select
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size='small' /> : null}
        {...props}
        placeholder=''
        options={options}
        style={{
          width: '100%',
        }}
      />
    </FloatLabel>
  );
};

const SearchBox = ({
  resource,
  filters = {},
  methodName = 'getList',
  resourceField,
  optionKey = 'id',
  valueKey = 'id',
  optionValue = 'name',
  additionalFirstOption,
  debounceTimeout = 800,
  filterOption = false,
  filterFunction = (el) => true,
  ...props
}) => {
  const { label, value, id, placeholder } = props;
  const customLabel = label || placeholder || '';
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (val) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      const requestParams = filters;
      if (val) {
        if (Array.isArray(resourceField)) {
          resourceField.forEach((fieldName) => {
            requestParams[fieldName] = val;
          });
        } else {
          requestParams[resourceField] = val;
        }
      }

      resource[methodName](requestParams).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        let optionsValue = newOptions;
        if (!Array.isArray(newOptions)) {
          optionsValue = newOptions.content;
        }
        setOptions(optionsValue);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [resource, filters, resourceField, debounceTimeout, methodName]);

  useEffect(() => {
    setOptions([]);

    if (!resource) {
      return;
    }

    setFetching(true);
    resource[methodName]({
      ...filters,
    }).then((newOptions) => {
      let optionsValue = newOptions;
      if (!Array.isArray(newOptions)) {
        optionsValue = newOptions.content;
      }
      setOptions(optionsValue);
      setFetching(false);
    });
  }, [resource, filters, methodName]);

  const renderOptions = options.filter(filterFunction).map((d) => {
    let renderValue = optionValue;
    if (typeof optionValue === 'function') {
      renderValue = optionValue(d);
    } else {
      renderValue = d[optionValue];
    }
    return (
      <Select.Option key={d[optionKey]} value={d[valueKey]}>
        {renderValue}
      </Select.Option>
    );
  });

  if (additionalFirstOption) {
    renderOptions.unshift(additionalFirstOption);
  }

  return (
    <FloatLabel label={customLabel} name={id} value={value}>
      <Select
        showSearch
        allowClear
        getPopupContainer={(trigger) => trigger.parentNode.parentNode}
        {...props}
        filterOption={filterOption}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size='small' /> : <Empty />}
        placeholder=''
        style={{
          width: '100%',
        }}
      >
        {renderOptions}
      </Select>
    </FloatLabel>
  );
};

Input.DebounceSelect = DebounceSelect;
Input.SearchBox = SearchBox;
Input.Switch = Switch;
Input.Select.Option = Select.Option;

export default Input;
