import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message, Upload, Card } from 'antd';
import { useParams } from 'react-router-dom';
import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import MessagePopup from '../../../components/MessagePopup';
import membersImportsService from '../../../services/membersImportsService';
import ProgressModal from '../../../components/ProgressModal';

export const ImportsList = () => {
  const [imports, setImports] = useState([]);
  const { id } = useParams();
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  useEffect(() => {
    membersImportsService.getImports().then((data) => {
      setImports(data);
    });
  }, [id]);

  const refresh = () => {
    membersImportsService.getImports().then((data) => {
      setImports(data);
    });
  };

  const columns = [
    {
      title: 'Autor',
      dataIndex: 'author',
      editable: false,
    },
    {
      title: 'Ilość rekordów',
      dataIndex: 'records',
      editable: false,
    },
    {
      title: 'Waga',
      dataIndex: 'fileSize',
      editable: false,
      render: (text) => `${text} kB`,
    },
    {
      title: 'Dodano',
      dataIndex: 'createDate',
      editable: false,
    },
  ];

  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('importFile', data.file);
    formData.append('fileName', data.file.name);

    setProgressModalVisible(true);

    membersImportsService
      .import(formData, {
        onUploadProgress: (progressEvent) => {
          setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
      .then((response) => {
        setProgressModalVisible(false);

        if (response.id) {
          message.success(
            <MessagePopup
              type='success'
              annotation={'Twoja baza danych została zaimportowana poprawnie'}
            >
              Import zakończony sukcesem
            </MessagePopup>
          );
          refresh();
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        } else if (err?.response?.status === 406) {
          message.error(
            <MessagePopup
              type={'error'}
              annotation={'Przekroczono limit lokali dla obecnego pakietu'}
            >
              Limit lokali został przekroczony
            </MessagePopup>
          );
        } else {
          message.error(
            <MessagePopup
              type={'error'}
              annotation={'Nie możemy zaimportować pliku'}
            >
              Import nie powiódł się
            </MessagePopup>
          );
        }
      });
  };

  const beforeUpload = (file) => {
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isLt15M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 15MB
        </MessagePopup>
      );
    }
    return isLt15M;
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      customRequest: addNewAction,
      showUploadList: false,
      beforeUpload,
    };

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploadProps}>
        <Button
          className='button-secondary'
          onClick={() => {}}
          style={{ width: '45px', height: '45px' }}
        >
          <Icon name='plus' />
        </Button>
      </Upload>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            data={imports}
            columns={columns}
            title='Lista importów'
            customAddButton={<CustomAddButton />}
            hideEdit
            tableClassName={imports.length !== 0 && 'contacts-table'}
          />
          <Card className='import-info-wrapper'>
            <Button className='button-secondary'>
              <a
                href='http://api.crm.brainstd.com/static/imports/czlonkowieImport.csv'
                target='_blank'
                rel='noreferrer'
                download
              >
                <Icon name='download' style={{ marginRight: 5 }} />
                Pobierz szablon
              </a>
            </Button>

            <div style={{ marginTop: 40 }}>
              <h2>Jak przygotować plik do importu?</h2>

              <span>
                Poniżej znajdziesz szczegółowe informacje jak przygotować plik,
                który pozwoli Ci zaimportować członków do systemu Manage CRM.
              </span>

              <ol>
                <li>
                  Akceptowany format plików: <strong>*.csv.</strong>
                </li>
                <li>
                  Maksymalny rozmiar pliku: <strong>15MB</strong>.
                </li>
                <li>
                  Jeśli w pliku wybrana pozycja zostanie pusta - dana wartość w
                  systemie pozostanie nieuzupełniona.
                </li>
                <li>
                  Jeśli w pliku wybrana pozycja zostanie uzupełniona niezgodnie
                  z poniższymi wytycznymi - cały wiersz nie zostanie
                  zaimportowany do systemu.
                </li>
                <li>
                  Akceptowane wartości oraz znaki w poszczególnych polach:
                </li>
              </ol>

              <ul>
                <li>
                  Pole <strong>"Imię"</strong>, <strong>"Drugie imię"</strong>{' '}
                  oraz <strong>"Nazwisko"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- cyfry</div>
                          <div>- znaki specjalne (z wykluczeniem "-")</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"E-mail"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- małe litery i cyfry</div>
                          <div>- znak “@” przed adresem domeny</div>
                          <div>
                            - poprawny adres domeny poczty (np. gmail.com,
                            wp.pl, itp.)
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- duże litery</div>
                          <div>- spacje</div>
                          <div>- polskie znaki</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Telefon"</strong>:<br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- od 7 do 15 cyfr</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- litery</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Rachunek do wpłat"</strong> - Podstawowy,
                  Fundusz remontowy, Miejsce postojowe:
                  <br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- 26 cyfr</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- litery</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Ulica"</strong>, <strong>"Numer"</strong>,{' '}
                  <strong>"Lokal"</strong> oraz <strong>"Miejscowość"</strong> -
                  adres zamieszkania i korespondencyjny:
                  <br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- cyfry</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Kod pocztowy"</strong> - adres zamieszkania i
                  korespondencyjny:
                  <br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>
                            - 5 cyfr w formacie <strong>00-000</strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                          <div>- znaki specjalne (z wykluczeniem "-")</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
                <li>
                  Pole <strong>"Kraj"</strong> - adres zamieszkania i
                  korespondencyjny:
                  <br />
                  <Row style={{ marginTop: 10, marginBottom: 20 }}>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon name='ok-circle' style={{ color: '#15D55E' }} />
                        <div>
                          <div style={{ fontWeight: 700 }}>TAK</div>
                          <div>- małe i duże litery</div>
                          <div>- polskie znaki</div>
                          <div>- spacje</div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} lg={12}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <Icon
                          name='error-circle'
                          style={{ color: '#EC434C' }}
                        />
                        <div>
                          <div style={{ fontWeight: 700 }}>NIE</div>
                          <div>- cyfry</div>
                          <div>- znaki specjalne (z wykluczeniem "-")</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              </ul>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
