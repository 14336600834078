import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageHeader, Button } from 'antd';

import { Datagrid, Icon } from '../../components';
import transactionsService from '../../services/transactionsService';

const columns = [
  {
    title: 'Nazwa transakcji',
    dataIndex: 'title',
    visible: true,
    // filterEnabled: true,
    filterEnabled: false,
    key: 'title',
    sorter: true,
    export: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Data dodania',
    dataIndex: 'transactionDate',
    visible: true,
    // filterEnabled: true,
    filterEnabled: false,
    key: 'transactionDate',
    filterType: 'date',
    sorter: true,
    export: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Kwota (brutto)',
    dataIndex: 'price',
    visible: true,
    // filterEnabled: true,
    filterEnabled: false,
    key: 'price',
    sorter: true,
    export: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (text ? `${parseFloat(text).toFixed(2)} zł` : ''),
    exportRender: (text, record) =>
      text ? `${parseFloat(text).toFixed(2)} zł` : '',
  },
];

const List = () => {
  const { memberId } = useParams();
  const history = useHistory();

  const TransactionsService = new transactionsService(memberId);

  return (
    <div>
      <Datagrid
        title={
          <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            backIcon={<Icon name='arrow-simply-left' />}
            onBack={() => window.history.back()}
            title={'Historia transakcji'}
          />
        }
        exportTitle={'Historia transakcji'}
        resource={`members/${memberId}/transactions`}
        dataProvider={TransactionsService}
        columns={columns}
        customCreate={
          <Button
            type='primary'
            onClick={() => {
              return history.push(`/members/${memberId}/transactions/create`);
            }}
          >
            Transakcja <Icon name='plus' style={{ marginLeft: 10 }} />
          </Button>
        }
        hideShow
        hideReset
        hideSms
        hideEmail
        // Temporary
        hideFilters
      />
    </div>
  );
};

export default List;
