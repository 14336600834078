import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Form,
  Button,
  Checkbox,
  PageHeader,
  Row,
  Col,
  Card,
  Upload,
  message,
  Typography,
} from 'antd';
import clientsService from '../../services/clientsService';
import Icon from '../../components/Icon';
import deleteConfirm from '../../components/deleteConfirm';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';
import sendPassword from '../../static/images/img-1.png';
import sendPassword2 from '../../static/images/img-m-2.png';
import sendPassword3 from '../../static/images/img-m-3.png';
import sendPassword4 from '../../static/images/img-m-4.png';

import Tabs from '../../components/Tabs';
import MessagePopup from '../../components/MessagePopup';
import { generateRandomPassword } from '../../helpers/passwordHelpers';
import ProgressModal from '../../components/ProgressModal';

const { InputNumber, Switch, Select, Password2, InputNumberInt } = Input;
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

const Edit = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [investmentId, setInvestmentId] = useState({});
  const [userAvatar, setUserAvatar] = useState('');
  const [showCorrespondence, setShowCorrespondence] = useState(false);

  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  let image = sendPassword;
  const selectedTheme = localStorage.getItem('theme') || 'default';
  if (selectedTheme === 'sky') {
    image = sendPassword3;
  }
  if (selectedTheme === 'brick') {
    image = sendPassword2;
  }
  if (selectedTheme === 'mech') {
    image = sendPassword4;
  }
  const history = useHistory();
  const [form] = Form.useForm();

  const showAlert = (resource) => {
    let expireDate = resource?.identity?.idExpireDate;
    if (!expireDate) {
      return false;
    }

    expireDate = new Date(expireDate);
    if (expireDate < new Date()) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');
    if (choosenInvestmentId) {
      setInvestmentId(JSON.parse(choosenInvestmentId));
    }
  }, []);

  useEffect(() => {
    clientsService.getOne(id).then((data) => {
      if (data?.identity?.idExpireDate) {
        // eslint-disable-next-line no-param-reassign
        data = {
          ...data,
          identity: {
            ...data.identity,
            idExpireDate: moment(data.identity.idExpireDate),
          },
        };
      }
      setUserData(data);
      setUserAvatar(data.avatar);
      const defaultValues = data;

      if (data.correspondenceAddress) {
        setShowCorrespondence(true);
        defaultValues.differentAddress = true;
      }

      if (data?.payments?.bankAccounts?.BASIC) {
        form.setFieldValue(['payments', 'bankAccounts', 'isBasic'], true);
      }
      if (data?.payments?.bankAccounts?.RENOVATION) {
        form.setFieldValue(['payments', 'bankAccounts', 'isRenovation'], true);
      }
      if (data?.payments?.bankAccounts?.PARKING) {
        form.setFieldValue(['payments', 'bankAccounts', 'isParking'], true);
      }

      form.setFieldsValue(defaultValues);
      if (showAlert(defaultValues)) {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: ['Termin ważności wygasł!'],
          },
        ]);
      }
    });
  }, [form, id]);

  const onFinish = (values) => {
    let data = { ...values };
    if (values?.identity?.idExpireDate) {
      data = {
        ...values,
        identity: {
          ...values.identity,
          idExpireDate: values.identity.idExpireDate.format('YYYY-MM-DD'),
        },
      };
    }
    if (!data.differentAddress) {
      data.correspondenceAddress = null;
    }
    data.payments.bankAccounts = {
      BASIC: values.payments.bankAccounts.isBasic
        ? values.payments.bankAccounts.BASIC
        : null,
      RENOVATION: values.payments.bankAccounts.isRenovation
        ? values.payments.bankAccounts.RENOVATION
        : null,
      PARKING: values.payments.bankAccounts.isRenovation
        ? values.payments.bankAccounts.PARKING
        : null,
    };

    clientsService
      .update(id, data)
      .then((response) => {
        if (response.id) {
          history.replace(
            `/investment/${investmentId}/members/${response.id}/show`
          );
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadAvatar = (data) => {
    const formData = new FormData();
    formData.append('avatar', data.file);

    setProgressModalVisible(true);

    clientsService
      .uploadAvatar(id, formData, {
        onUploadProgress: (progressEvent) => {
          setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
      .then((response) => {
        setProgressModalVisible(false);
        message.success(
          <MessagePopup type={'success'}>Pomyślnie dodano avatar</MessagePopup>
        );

        setUserAvatar(response.avatar);
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(
        <MessagePopup type={'error'}>
          Dozwolone są tylko pliki graficzne
        </MessagePopup>
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 2MB
        </MessagePopup>
      );
    }
    return isJpgOrPng && isLt2M;
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);

    if (event.target.checked) {
      const correspondenceCountry = form.getFieldValue([
        'correspondenceAddress',
        'country',
      ]);

      if (!correspondenceCountry) {
        form.setFieldsValue({
          correspondenceAddress: { country: 'Polska' },
        });
      }
    }
  };

  const handleOk = () => {
    clientsService
      .delete(id)
      .then(() => history.goBack())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const fillPasswordsHandler = () => {
    const randomPassword = generateRandomPassword(8);

    form.setFieldsValue({
      password: randomPassword,
      confirm: randomPassword,
    });
  };

  const handleCancel = () => {
    // history.goBack();
    history.replace(`/investment/${investmentId}/members/${id}/show`);
  };

  const HeaderTitle = () => {
    if (!userData.firstName) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz:{' '}
        <span className='header-subtitle'>
          {userData.firstName} {userData.lastName}
        </span>
      </span>
    );
  };

  const IdentityTab = () => {
    if (showAlert(userData)) {
      return (
        <span>
          <Icon name='important' />
          Potwierdzenie tożsamości
        </span>
      );
    }

    return <span>Potwierdzenie tożsamości</span>;
  };

  const onFieldsChange = () => {
    let expireDate = form.getFieldValue(['identity', 'idExpireDate']);
    if (expireDate) {
      expireDate = new Date(expireDate);
      if (expireDate < new Date()) {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: ['Termin ważności wygasł!'],
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: [],
          },
        ]);
      }
    }
  };

  const sendPasswordAction = () => {
    const password = form.getFieldValue('password');
    const confirm = form.getFieldValue('confirm');
    if (password !== confirm) {
      return;
    }

    if (password.length < 8) {
      return;
    }
    clientsService
      .sendPassword(id, { password })
      .then(() => {
        message.success(
          <MessagePopup type={'success'}>Hasło zostało wysłane</MessagePopup>
        );
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  return (
    <div className='form-create'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => history.goBack()}
        title={<HeaderTitle />}
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <h2>Miniaturka</h2>
                          <Form.Item
                            name='dragger'
                            valuePropName='fileList'
                            getValueFromEvent={normFile}
                            noStyle
                          >
                            <Upload.Dragger
                              name='avatar'
                              listType='picture-card'
                              className='avatar-uploader'
                              showUploadList={false}
                              customRequest={uploadAvatar}
                              action='avatar'
                              beforeUpload={beforeUpload}
                            >
                              <div className='upload-avatar-wrapper'>
                                {userAvatar && (
                                  <img
                                    src={`${baseUrl}${userAvatar}`}
                                    alt='avatar'
                                  />
                                )}
                                {/* <Button
                                  size='large'
                                  className='change-button'
                                  type='primary'
                                >
                                  Zmień <Icon name='edit' />
                                </Button> */}
                              </div>
                            </Upload.Dragger>
                          </Form.Item>
                          <span
                            style={{
                              marginTop: '20px',
                              fontSize: '12px',
                              color: '#C0D6E5',
                            }}
                          >
                            Zalecany format plików: kwadrat
                          </span>
                        </div>
                        <div className='form-section'>
                          <h2>Typ konta</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='isCompany'
                                label='Konto firmowe'
                                valuePropName='checked'
                              >
                                <Switch size='large' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) =>
                            prev.isCompany !== current.isCompany
                          }
                        >
                          {({ getFieldValue }) =>
                            !getFieldValue('isCompany') && (
                              <>
                                <div className='form-section'>
                                  <h2>Dane osobowe</h2>
                                  <Row gutter={20}>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='firstName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Imię'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item name='secondName'>
                                        <Input
                                          size='large'
                                          placeholder='Drugie imię (opcjonalne)'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='lastName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwisko'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )
                          }
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) =>
                            prev.isCompany !== current.isCompany
                          }
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('isCompany') && (
                              <>
                                <div className='form-section'>
                                  <h2>Dane firmowe</h2>
                                  <Row gutter={20}>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='name'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwa firmy'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='nip'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input.MaskedInput
                                          mask='000-000-00-00'
                                          size='large'
                                          placeholder='NIP'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )
                          }
                        </Form.Item>
                        <div className='form-section'>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, current) =>
                              prev.isCompany !== current.isCompany
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue('isCompany') ? (
                                <h2>Adres siedziby</h2>
                              ) : (
                                <h2>Adres zamieszkania</h2>
                              )
                            }
                          </Form.Item>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'number']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'local']}>
                                <Input size='large' placeholder='Mieszkanie' />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item>
                            <Form.Item
                              name='differentAddress'
                              valuePropName='checked'
                              noStyle
                            >
                              <Checkbox onChange={correspondenceShow}>
                                Inny adres do korespondencji
                              </Checkbox>
                            </Form.Item>
                          </Form.Item>
                          {showCorrespondence && (
                            <>
                              <h2>Adres do korespondencji</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'street']}
                                  >
                                    <Input size='large' placeholder='Ulica' />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'number']}
                                  >
                                    <Input size='large' placeholder='Numer' />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'local']}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Mieszkanie'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'city']}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Miejscowość'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'zip']}
                                  >
                                    <Input.MaskedInput
                                      mask='00-000'
                                      size='large'
                                      placeholder='Kod pocztowy'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'country']}
                                  >
                                    <CountrySelect />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                        <div className='form-section'>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, current) =>
                              prev.isCompany !== current.isCompany
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue('isCompany') ? (
                                <h2>Osoba do kontaktu</h2>
                              ) : (
                                <h2>Dane kontaktowe</h2>
                              )
                            }
                          </Form.Item>
                          <Row gutter={20}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, current) =>
                                prev.isCompany !== current.isCompany
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue('isCompany') && (
                                  <>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='firstName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Imię'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='lastName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwisko'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </>
                                )
                              }
                            </Form.Item>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='email'
                                rules={[
                                  {
                                    required: false,
                                    message: 'Proszę uzupełnić adres email',
                                  },
                                  {
                                    type: 'email',
                                    message: 'Niepoprawny adres email',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='E-Mail' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item name='mobile'>
                                <InputNumber
                                  size='large'
                                  placeholder='Telefon'
                                  className='hide-arrows'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className='form-section'>
                          <h2>Status konta</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='enabled'
                                label='Aktywne'
                                valuePropName='checked'
                              >
                                <Switch size='large' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Card className='create-form-password-card'>
                      <Row>
                        <Col xs={24} sm={{ span: 10, offset: 2 }}>
                          <div className='form-section'>
                            <h2>Dane do logowania</h2>
                            <Form.Item
                              name='username'
                              rules={[
                                {
                                  required: true,
                                  message: 'Proszę uzupełnić login',
                                },
                              ]}
                            >
                              <Input size='large' placeholder='Login' />
                            </Form.Item>
                            <Form.Item
                              name='password'
                              rules={[
                                { message: 'Pole wymagane' },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      /\d/.test(value) &&
                                      /[A-Z]/.test(value) &&
                                      /[a-z]/.test(value) &&
                                      value.length >= 8
                                    ) {
                                      return Promise.resolve();
                                    } else if (!value) {
                                      return Promise.resolve();
                                    }

                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      'Hasło nieprawidłowe'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Password2 size='large' placeholder='Hasło' />
                            </Form.Item>
                            <Form.Item
                              name='confirm'
                              dependencies={['password']}
                              hasFeedback
                              rules={[
                                {
                                  message: 'Potwierdź hasło',
                                },
                                {
                                  min: 8,
                                  message:
                                    'Hasło musi posiadać conajmniej 8 znaków.',
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      !value ||
                                      getFieldValue('password') === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      'Hasło nieprawidłowe'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Password2
                                size='large'
                                placeholder='Powtórz hasło'
                              />
                            </Form.Item>
                            <div
                              className='account-subscription-details-limits-addnotation-details'
                              style={{ color: '#C0D6E5', marginBottom: 24 }}
                            >
                              * Min. 8 znaków, wielka i mała litera, jedna cyfra
                            </div>
                            <Form.Item>
                              <Text
                                type='secondary'
                                className='generate-password'
                                underline
                                onClick={fillPasswordsHandler}
                              >
                                Generuj hasło
                              </Text>
                            </Form.Item>

                            <Form.Item>
                              <Button
                                className='create-form-button-send-password'
                                size='large'
                                onClick={() => sendPasswordAction()}
                              >
                                Wyślij login i hasło <Icon name='access' />
                              </Button>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={0} sm={10}>
                          <div className='send-password-image-wrapper'>
                            <img src={image} alt='send password icon' />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </TabPane>

                <TabPane tab='Rozliczenia' key='settlements'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section member-settlements-bankAccounts'>
                          <h2>Rachunki do wpłat</h2>
                          <Row gutter={[10, 10]}>
                            <Col xs={24}>
                              <Card>
                                <Row gutter={20} style={{ marginBottom: 16 }}>
                                  <Col style={{ fontSize: 18 }} flex={'auto'}>
                                    Podstawowy
                                  </Col>
                                  <Col>
                                    <Form.Item
                                      label='Indywidualny'
                                      name={[
                                        'payments',
                                        'bankAccounts',
                                        'isBasic',
                                      ]}
                                      valuePropName='checked'
                                      className='label-narrow'
                                    >
                                      <Switch />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prev, current) =>
                                    prev.payments.bankAccounts?.isBasic !==
                                    current.payments.bankAccounts?.isBasic
                                  }
                                >
                                  {({ getFieldValue }) =>
                                    getFieldValue([
                                      'payments',
                                      'bankAccounts',
                                      'isBasic',
                                    ]) && (
                                      <div style={{ marginTop: 20 }}>
                                        <Form.Item
                                          name={[
                                            'payments',
                                            'bankAccounts',
                                            'BASIC',
                                          ]}
                                          rules={[
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (
                                                  getFieldValue([
                                                    'payments',
                                                    'bankAccounts',
                                                    'BASIC',
                                                  ]).length !== 26
                                                ) {
                                                  return Promise.reject(
                                                    'Rachunek bankowy składa się z 26 liczb!'
                                                  );
                                                }
                                                return Promise.resolve();
                                              },
                                            }),
                                          ]}
                                        >
                                          <Input
                                            size='large'
                                            placeholder='Numer rachunku'
                                          />
                                        </Form.Item>
                                      </div>
                                    )
                                  }
                                </Form.Item>
                              </Card>
                            </Col>
                            <Col xs={24}>
                              <Card>
                                <Row gutter={20} style={{ marginBottom: 16 }}>
                                  <Col style={{ fontSize: 18 }} flex={'auto'}>
                                    Fundusz remontowy
                                  </Col>
                                  <Col>
                                    <Form.Item
                                      label='Indywidualny'
                                      name={[
                                        'payments',
                                        'bankAccounts',
                                        'isRenovation',
                                      ]}
                                      valuePropName='checked'
                                      className='label-narrow'
                                    >
                                      <Switch />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prev, current) =>
                                    prev.payments.bankAccounts?.isRenovation !==
                                    current.payments.bankAccounts?.isRenovation
                                  }
                                >
                                  {({ getFieldValue }) =>
                                    getFieldValue([
                                      'payments',
                                      'bankAccounts',
                                      'isRenovation',
                                    ]) && (
                                      <div style={{ marginTop: 20 }}>
                                        <Form.Item
                                          name={[
                                            'payments',
                                            'bankAccounts',
                                            'RENOVATION',
                                          ]}
                                          rules={[
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (
                                                  getFieldValue([
                                                    'payments',
                                                    'bankAccounts',
                                                    'RENOVATION',
                                                  ]).length !== 26
                                                ) {
                                                  return Promise.reject(
                                                    'Rachunek bankowy składa się z 26 liczb!'
                                                  );
                                                }
                                                return Promise.resolve();
                                              },
                                            }),
                                          ]}
                                        >
                                          <Input
                                            size='large'
                                            placeholder='Numer rachunku'
                                          />
                                        </Form.Item>
                                      </div>
                                    )
                                  }
                                </Form.Item>
                              </Card>
                            </Col>
                            <Col xs={24}>
                              <Card>
                                <Row gutter={20} style={{ marginBottom: 16 }}>
                                  <Col style={{ fontSize: 18 }} flex={'auto'}>
                                    Miejsce postojowe
                                  </Col>
                                  <Col>
                                    <Form.Item
                                      label='Indywidualny'
                                      name={[
                                        'payments',
                                        'bankAccounts',
                                        'isParking',
                                      ]}
                                      valuePropName='checked'
                                      className='label-narrow'
                                    >
                                      <Switch />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prev, current) =>
                                    prev.payments.bankAccounts?.isParking !==
                                    current.payments.bankAccounts?.isParking
                                  }
                                >
                                  {({ getFieldValue }) =>
                                    getFieldValue([
                                      'payments',
                                      'bankAccounts',
                                      'isParking',
                                    ]) && (
                                      <div style={{ marginTop: 20 }}>
                                        <Form.Item
                                          name={[
                                            'payments',
                                            'bankAccounts',
                                            'PARKING',
                                          ]}
                                          rules={[
                                            ({ getFieldValue }) => ({
                                              validator(_, value) {
                                                if (
                                                  getFieldValue([
                                                    'payments',
                                                    'bankAccounts',
                                                    'PARKING',
                                                  ]).length !== 26
                                                ) {
                                                  return Promise.reject(
                                                    'Rachunek bankowy składa się z 26 liczb!'
                                                  );
                                                }
                                                return Promise.resolve();
                                              },
                                            }),
                                          ]}
                                        >
                                          <Input
                                            size='large'
                                            placeholder='Numer rachunku'
                                          />
                                        </Form.Item>
                                      </div>
                                    )
                                  }
                                </Form.Item>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                        <span
                          style={{
                            marginTop: '20px',
                            fontSize: '12px',
                            color: '#C0D6E5',
                          }}
                        >
                          * Rachunek indywidualny członka ma wyższy priorytet
                          niż rachunek inwestycji.
                        </span>
                      </Col>
                    </Row>
                  </div>
                </TabPane>

                <TabPane tab='Alerty' key='alerts'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='permissions'>
                          <div className='form-section'>
                            <h2>Powiadomienia</h2>
                            <Form.Item
                              label='E-mail'
                              name={['notifications', 'email']}
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                            <Form.Item
                              label='SMS'
                              name={['notifications', 'sms']}
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
              <Button
                className='delete-btn'
                type='danger'
                onClick={() => deleteConfirm(handleOk)}
              >
                <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
              </Button>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
