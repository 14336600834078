import React, { useEffect, useState } from 'react';
import { PageHeader, Tooltip, Button, Col, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { Icon, Datagrid } from '../../components';
import clientsService from '../../services/clientsService';
import investmentsService from '../../services/investmentsService';
import premisesService from '../../services/premisesService';
import { ChoosenAllSwitch } from '../../components/ChoosenAllSwitch';

import premiseTypeMap from '../../helpers/premiseTypeMap';

import Spin from '../../components/Spin';

const showAlert = (resource) => {
  let expireDate = resource?.identity?.idExpireDate;
  if (!expireDate) {
    return false;
  }

  expireDate = new Date(expireDate);
  if (expireDate < new Date()) {
    return true;
  }

  return false;
};

const List = () => {
  const history = useHistory();
  const [canEdit, setCanEdit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showAllMembers, setShowAllMembers] = useState(false);
  const [investment, setInvestment] = useState();
  const [id, setId] = useState();
  const [premises, setPremises] = useState([]);
  const [investmentPremises, setInvestmentPremises] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [filterInvestmentId, setFilterInvestmentId] = useState();

  const columns = [
    {
      title: 'Nazwisko',
      dataIndex: 'lastName',
      visible: true,
      filterEnabled: false,
      key: 'lastName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Imię',
      dataIndex: 'firstName',
      visible: true,
      filterEnabled: false,
      key: 'firstName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Nazwisko, imię',
      dataIndex: 'firstOrLastName',
      visible: false,
      filterEnabled: true,
      key: 'firstOrLastName',
      sorter: false,
      export: false,
    },
    {
      title: 'Alerty',
      dataIndex: 'notifications',
      visible: false,
      key: 'notifications',
      sorter: false,
      export: false,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptionsObject: true,
      filterOptions: [
        { value: 'email', label: 'E-mail' },
        { value: 'sms', label: 'SMS' },
      ],
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      visible: true,
      key: 'mobile',
      sorter: true,
      filterEnabled: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <a className='datagrid-link' href={`tel:${text}`}>
          {text}
        </a>
      ),
      exportRender: (text, source) => text,
    },
    {
      title: 'Adres e-mail',
      dataIndex: 'email',
      visible: true,
      key: 'email',
      sorter: true,
      filterEnabled: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <a className='datagrid-link' href={`mailto:${text}`}>
          {text}
        </a>
      ),
      exportRender: (text, source) => text,
    },
    {
      title: 'Alerty',
      dataIndex: 'notifications',
      visible: true,
      key: 'notifications',
      sorter: true,
      filterEnabled: false,
      render: (text, source) => {
        if (text.sms && text.email) {
          return 'SMS, E-mail';
        } else if (text.sms) {
          return 'SMS';
        } else if (text.email) {
          return 'E-mail';
        }

        return '';
      },
      exportRender: (text, source) => {
        return `E-mail: ${text.email ? 'TAK' : 'NIE'}, SMS: ${
          text.sms ? 'TAK' : 'NIE'
        }`;
      },
    },
    ...(showAllMembers
      ? [
          {
            title: 'Nieruchomość',
            dataIndex: 'investment',
            visible: true,
            key: 'investmentId',
            sorter: true,
            filterFullWidth: true,
            filterEnabled: true,
            filterType: 'select',
            filterOptions: investments.map((investment) => ({
              value: investment.id,
              label: investment.name,
            })),
            onFilterChange: (e) => setFilterInvestmentId(e),
            render: (text, source) => {
              if (source.investmentsOwnerList.length !== 0) {
                const investments = source.investmentsOwnerList.map(
                  (investment) => investment.name
                );

                const output = investments.join(', ');

                if (output.length > 35) {
                  return (
                    <Tooltip title={output}>
                      {output.slice(0, 35) + '...'}
                    </Tooltip>
                  );
                } else {
                  return output;
                }
              }

              return '';
            },
            exportRender: (text, source) => {
              if (source.investmentsOwnerList.length !== 0) {
                const investments = source.investmentsOwnerList.map(
                  (investment) => investment.name
                );

                return investments.join(', ');
              }

              return '';
            },
          },
        ]
      : []),
    {
      title: 'Typ lokalu',
      dataIndex: 'premisesTypes',
      visible: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        { value: 'RENT', label: 'Mieszkanie' },
        { value: 'STORAGE', label: 'Komórka lokatorska' },
        { value: 'PARKING', label: 'Miejsce postojowe' },
        { value: 'SERVICE', label: 'Lokal usługowy' },
      ],
      key: 'premisesTypes',
      onFilterChange: (e) => {
        setPremises(
          investmentPremises.filter((premise) => {
            if (!e) {
              return true;
            } else {
              return premise.type === e;
            }
          })
        );
      },
      sorter: false,
      export: false,
    },
    {
      title: 'Lokal',
      dataIndex: 'premise',
      visible: true,
      key: 'premisesIds',
      sorter: true,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptions: premises.map((premise) => ({
        value: premise.id,
        label: premise.name,
      })),
      render: (text, source) => {
        if (source.premisesOwnerList.length !== 0) {
          const premises = source.premisesOwnerList.map(
            (premise) => `${premiseTypeMap(premise.type)} ${premise.name}`
          );

          const output = premises.join(', ');

          if (output.length > 35) {
            return (
              <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>
            );
          } else {
            return output;
          }
        }

        return '';
      },
      exportRender: (text, source) => {
        if (source.premisesOwnerList.length !== 0) {
          const premises = source.premisesOwnerList.map(
            (premise) => `${premiseTypeMap(premise.type)} ${premise.name}`
          );

          return premises.join(', ');
        }

        return '';
      },
      requireIfSet: {
        field: 'premisesTypes',
        message: 'Jeśli wybrałeś typ lokalu wybierz lokale',
      },
    },
    {
      title: 'Saldo (brutto)',
      dataIndex: 'balance',
      visible: false,
      key: 'balance',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (balance) => {
        if (balance < 0) {
          return (
            <span style={{ color: '#15D55E', fontWeight: 700 }}>
              {parseFloat(-balance).toFixed(2).replace('.', ',')} zł
            </span>
          );
        } else if (balance > 0) {
          return (
            <span style={{ color: '#EC434C', fontWeight: 700 }}>
              {parseFloat(-balance).toFixed(2).replace('.', ',')} zł
            </span>
          );
        } else {
          return <>{parseFloat(-balance).toFixed(2).replace('.', ',')} zł</>;
        }
      },
      exportRender: (balance) =>
        `${parseFloat(-balance).toFixed(2).replace('.', ',')} zł`,
    },
  ];

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getList().then((data) => {
      setInvestments(data.content);
    });
  }, []);

  useEffect(() => {
    if (filterInvestmentId && showAllMembers) {
      const PremisesService = new premisesService(filterInvestmentId);

      PremisesService.getList().then((data) => {
        setPremises(data.content);
        setInvestmentPremises(data.content);
      });
    } else if (!filterInvestmentId && showAllMembers) {
      setPremises([]);
      setInvestmentPremises([]);
    }
  }, [filterInvestmentId, showAllMembers]);

  useEffect(() => {
    if (!showAllMembers && id) {
      const PremisesService = new premisesService(id);

      PremisesService.getList().then((data) => {
        setPremises(data.content);
        setInvestmentPremises(data.content);
      });
    } else if (!showAllMembers && !id) {
      setPremises([]);
      setInvestmentPremises([]);
    }
  }, [showAllMembers, id]);

  useEffect(() => {
    if (id) {
      const InvestmentsService = new investmentsService();
      InvestmentsService.getDetails(id).then((data) => {
        setInvestment(data.investment);
      });
    }
    setLoading(false);
  }, [id]);

  const isRodoAccepted = (record) => {
    if (!record.isRodoAccepted === true) {
      return '';
    }
    return (
      <Tooltip title='Klauzula potwierdzona'>
        <Icon name='ok-circle' style={{ fontSize: '18px' }} />
      </Tooltip>
    );
  };

  useEffect(() => {
    const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');
    if (choosenInvestmentId) {
      setId(JSON.parse(choosenInvestmentId));
    }
  }, []);

  const CustomCreate = () => {
    if (!canEdit) {
      return '';
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          className='button-secondary import-button'
          onClick={() => {
            return history.push(`/members/import`);
          }}
        >
          <span style={{ marginRight: 5 }}>Importuj</span>
          <Icon name='download' />
        </Button>

        <Button
          type='primary'
          onClick={() => {
            return history.push(`/members/create`);
          }}
        >
          Dodaj <Icon name='plus' style={{ marginLeft: 10 }} />
        </Button>
      </div>
    );
  };

  const HeaderTitle = () => {
    if (!investment?.name || showAllMembers) {
      return <span>Członkowie</span>;
    }
    return (
      <span>
        Członkowie: <span className='header-subtitle'>{investment?.name}</span>
      </span>
    );
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <Datagrid
            title={
              <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                backIcon={<Icon name='arrow-simply-left' />}
                title={<HeaderTitle />}
              />
            }
            exportTitle={'Członkowie'}
            resource={`investment/${id}/members`}
            customBaseUrlKey={'members'}
            dataProvider={clientsService}
            columns={columns}
            filters={{
              role: 'ROLE_COMMUNITY_MEMBER',
              ...(!showAllMembers && { investmentId: id }),
            }}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            hideEdit={!canEdit}
            hideCreate={!canEdit}
            canEdit={canEdit}
            showAlert={showAlert}
            // customRowActions={[isRodoAccepted]}
            customCreate={<CustomCreate />}
            customShowAllMembers={
              <ChoosenAllSwitch
                showAll={showAllMembers}
                setShowAll={setShowAllMembers}
              />
            }
            showSendMembersMessage={true}
            showSendMembersTemplate={true}
            isAdmin={false}
            showAddTransaction={true}
          />
        </div>
      )}
    </>
  );
};

export default List;
