import React, { useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  PageHeader,
  Row,
  message,
  Typography,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import clientsService from '../../services/clientsService';
import Icon from '../../components/Icon';
import sendPassword from '../../static/images/img-1.png';
import sendPassword2 from '../../static/images/img-m-2.png';
import sendPassword3 from '../../static/images/img-m-3.png';
import sendPassword4 from '../../static/images/img-m-4.png';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';
import Tabs from '../../components/Tabs';
import IdentityConfirmForm from '../../components/IdentityConfirmForm';

import { generateRandomPassword } from '../../helpers/passwordHelpers';
import MessagePopup from '../../components/MessagePopup';

const { InputNumber, Switch, Password2, InputNumberInt } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;

const Create = () => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [showCorrespondence, setShowCorrespondence] = useState(false);

  let image = sendPassword;

  const selectedTheme = localStorage.getItem('theme') || 'default';
  if (selectedTheme === 'sky') {
    image = sendPassword3;
  }
  if (selectedTheme === 'brick') {
    image = sendPassword2;
  }
  if (selectedTheme === 'mech') {
    image = sendPassword4;
  }

  const onFinish = (values) => {
    let data = {
      roles: ['ROLE_COMMUNITY_MEMBER'],
      ...values,
      isPasswordChanged: false,
    };

    clientsService
      .create(data)
      .then((response) => {
        if (response.id) {
          history.replace(`/members/${response.id}/show`);
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
        if (err?.response?.status === 409) {
          form.setFields([
            {
              name: 'username',
              errors: ['Podany login juz istnieje'],
            },
          ]);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);
  };

  const fillPasswordsHandler = () => {
    const randomPassword = generateRandomPassword(8);

    form.setFieldsValue({
      password: randomPassword,
      confirm: randomPassword,
    });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const onFieldsChange = () => {
    let expireDate = form.getFieldValue(['identity', 'idExpireDate']);
    if (expireDate) {
      expireDate = new Date(expireDate);
      if (expireDate < new Date()) {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: ['Termin ważności wygasł!'],
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: [],
          },
        ]);
      }
    }
  };

  return (
    <div className='form-create'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        // onBack={() => history.push('/members')}
        onBack={() => history.goBack()}
        title='Dodaj członka'
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          initialValues={{
            enabled: true,
            role: ['ROLE_COMMUNITY_MEMBER'],
            address: { country: 'Polska' },
            correspondenceAddress: { country: 'Polska' },
            notifications: { email: true, sms: true },
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <h2>Typ konta</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='isCompany'
                                label='Konto firmowe'
                                valuePropName='checked'
                              >
                                <Switch size='large' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) =>
                            prev.isCompany !== current.isCompany
                          }
                        >
                          {({ getFieldValue }) =>
                            !getFieldValue('isCompany') && (
                              <>
                                <div className='form-section'>
                                  <h2>Dane osobowe</h2>
                                  <Row gutter={20}>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='firstName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Imię'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item name='secondName'>
                                        <Input
                                          size='large'
                                          placeholder='Drugie imię (opcjonalne)'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='lastName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwisko'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )
                          }
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) =>
                            prev.isCompany !== current.isCompany
                          }
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('isCompany') && (
                              <>
                                <div className='form-section'>
                                  <h2>Dane firmowe</h2>
                                  <Row gutter={20}>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='name'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwa firmy'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='nip'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input.MaskedInput
                                          mask='000-000-00-00'
                                          size='large'
                                          placeholder='NIP'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )
                          }
                        </Form.Item>
                        <div className='form-section'>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, current) =>
                              prev.isCompany !== current.isCompany
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue('isCompany') ? (
                                <h2>Adres siedziby</h2>
                              ) : (
                                <h2>Adres zamieszkania</h2>
                              )
                            }
                          </Form.Item>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'number']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'local']}>
                                <Input size='large' placeholder='Mieszkanie' />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item>
                            <Form.Item
                              name='differentAddress'
                              valuePropName='checked'
                              noStyle
                            >
                              <Checkbox onChange={correspondenceShow}>
                                Inny adres do korespondencji
                              </Checkbox>
                            </Form.Item>
                          </Form.Item>
                          {showCorrespondence && (
                            <>
                              <h2>Adres do korespondencji</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'street']}
                                  >
                                    <Input size='large' placeholder='Ulica' />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'number']}
                                  >
                                    <Input size='large' placeholder='Numer' />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'local']}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Mieszkanie'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'city']}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Miejscowość'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'zip']}
                                  >
                                    <Input.MaskedInput
                                      mask='00-000'
                                      size='large'
                                      placeholder='Kod pocztowy'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'country']}
                                  >
                                    <CountrySelect />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                        <div className='form-section'>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, current) =>
                              prev.isCompany !== current.isCompany
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue('isCompany') ? (
                                <h2>Osoba do kontaktu</h2>
                              ) : (
                                <h2>Dane kontaktowe</h2>
                              )
                            }
                          </Form.Item>
                          <Row gutter={20}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, current) =>
                                prev.isCompany !== current.isCompany
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue('isCompany') && (
                                  <>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='firstName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Imię'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='lastName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwisko'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </>
                                )
                              }
                            </Form.Item>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='email'
                                rules={[
                                  {
                                    required: false,
                                    message: 'Proszę uzupełnić adres email',
                                  },
                                  {
                                    type: 'email',
                                    message: 'Niepoprawny adres email',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='E-Mail' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item name='mobile'>
                                <InputNumber
                                  size='large'
                                  placeholder='Telefon'
                                  className='hide-arrows'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className='form-section'>
                          <h2>Status konta</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='enabled'
                                label='Aktywne'
                                valuePropName='checked'
                              >
                                <Switch size='large' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Card className='create-form-password-card'>
                      <Row>
                        <Col xs={24} sm={{ span: 10, offset: 2 }}>
                          <div className='form-section'>
                            <h2>Dane do logowania</h2>
                            <Form.Item
                              name='username'
                              rules={[
                                {
                                  required: true,
                                  message: 'Proszę uzupełnić login',
                                },
                              ]}
                            >
                              <Input size='large' placeholder='Login' />
                            </Form.Item>
                            <Form.Item
                              name='password'
                              rules={[
                                { message: 'Pole wymagane' },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      /\d/.test(value) &&
                                      /[A-Z]/.test(value) &&
                                      /[a-z]/.test(value) &&
                                      value.length >= 8
                                    ) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      'Hasło nieprawidłowe'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Password2 size='large' placeholder='Hasło' />
                            </Form.Item>
                            <Form.Item
                              name='confirm'
                              dependencies={['password']}
                              hasFeedback
                              rules={[
                                {
                                  message: 'Potwierdź hasło',
                                },
                                {
                                  min: 8,
                                  message:
                                    'Hasło musi posiadać conajmniej 8 znaków.',
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      !value ||
                                      getFieldValue('password') === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      'Hasło nieprawidłowe'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Password2
                                size='large'
                                placeholder='Powtórz hasło'
                              />
                            </Form.Item>
                            <div
                              className='account-subscription-details-limits-addnotation-details'
                              style={{ color: '#C0D6E5', marginBottom: 24 }}
                            >
                              * Min. 8 znaków, wielka i mała litera, jedna cyfra
                            </div>
                            <Form.Item>
                              <Text
                                type='secondary'
                                className='generate-password'
                                underline
                                onClick={fillPasswordsHandler}
                              >
                                Generuj hasło
                              </Text>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={0} sm={10}>
                          <div className='send-password-image-wrapper'>
                            <img src={image} alt='send password icon' />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </TabPane>
                <TabPane tab='Alerty' key='alerts' forceRender>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='permissions'>
                          <div className='form-section'>
                            <h2>Powiadomienia</h2>
                            <Form.Item
                              label='E-mail'
                              name={['notifications', 'email']}
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                            <Form.Item
                              label='SMS'
                              name={['notifications', 'sms']}
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
